exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_SkeletonLight_1O6OIQZJ9IzohdWZnDZlC {\n  background: linear-gradient(to right, #e1e1e1 8%, #e9e9e9 38%, #e1e1e1 54%);\n}\n\n.IA_SkeletonDark_1hG0xPMZLrsLPmDBK4R4On {\n  background: linear-gradient(to right, #2d2d2d 8%, #393939 38%, #2d2d2d 54%);\n}\n\n.IA_Skeleton_UtgdncGzVwzTFEsiUR8y4 {\n  background-color: rgba(0, 0, 0, .15);\n  animation-duration: 1.5s;\n  animation-fill-mode: forwards;\n  animation-iteration-count: infinite;\n  animation-timing-function: linear;\n  background-size: 500px 500px;\n  animation-name: skeletonAnimation_2qFIunVKEWAoFXVqv5-H6t;\n}\n\n@keyframes skeletonAnimation_2qFIunVKEWAoFXVqv5-H6t {\n  0% {\n    background-position: -500px 0;\n  }\n\n  100% {\n    background-position: 500px 0;\n  }\n}", ""]);

// Exports
exports.locals = {
	"IA_SkeletonLight": "IA_SkeletonLight_1O6OIQZJ9IzohdWZnDZlC",
	"IA_SkeletonDark": "IA_SkeletonDark_1hG0xPMZLrsLPmDBK4R4On",
	"IA_Skeleton": "IA_Skeleton_UtgdncGzVwzTFEsiUR8y4",
	"skeletonAnimation": "skeletonAnimation_2qFIunVKEWAoFXVqv5-H6t"
};