exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_listElementHeader_1NSSF5EHGh9YvHm2r7ydaR {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  overflow: hidden;\n  font-size: 12px;\n  padding-left: 15px;\n  box-sizing: border-box;\n}\n\n.IA_previewColumn_3stBqsy8EhIF3H843nZ6Ef {\n  flex: 0.2;\n}\n\n.IA_iconColumn_2rk2vRYh2Mg-cZp_zGLp1d {\n  flex: 0.4;\n  padding-right: 10px;\n  box-sizing: border-box;\n}\n\n.IA_dateColumn_232gjG1QJcX7UAHsBzRQ8A {\n  flex: 0.5;\n}\n\n.IA_column_1C5whwfymW4uWtVlkQHoHm {\n  flex: 1;\n}\n\n.IA_buttons_2UIP3QSo7ecU9L-RzIi9Mt {\n  padding-right: 10px;\n}", ""]);

// Exports
exports.locals = {
	"IA_listElementHeader": "IA_listElementHeader_1NSSF5EHGh9YvHm2r7ydaR",
	"IA_previewColumn": "IA_previewColumn_3stBqsy8EhIF3H843nZ6Ef",
	"IA_iconColumn": "IA_iconColumn_2rk2vRYh2Mg-cZp_zGLp1d",
	"IA_dateColumn": "IA_dateColumn_232gjG1QJcX7UAHsBzRQ8A",
	"IA_column": "IA_column_1C5whwfymW4uWtVlkQHoHm",
	"IA_buttons": "IA_buttons_2UIP3QSo7ecU9L-RzIi9Mt"
};