exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_companyInformation_3ZK_lpAz1HrhW63Cu4ox3L {\n  width: 100%;\n  margin-top: 20px;\n  margin-bottom: 20px;\n  float: left;\n}\n\n.IA_divider_1afZJxu3rCoCtGowj6iCzv, .IA_dividerDarkMode_3VPBD8dY1l-vzvYC9JvqNU {\n  height: 1px;\n  width: 100%;\n  background-color: #eeeeee;\n  margin-top: 40px;\n  margin-bottom: 20px;\n  float: left;\n}\n\n.IA_billingInfo_3dgwjtRgjp_cSb_lWrS5UW {\n  font-size: 14px;\n}", ""]);

// Exports
exports.locals = {
	"IA_companyInformation": "IA_companyInformation_3ZK_lpAz1HrhW63Cu4ox3L",
	"IA_divider": "IA_divider_1afZJxu3rCoCtGowj6iCzv",
	"IA_dividerDarkMode": "IA_dividerDarkMode_3VPBD8dY1l-vzvYC9JvqNU",
	"IA_billingInfo": "IA_billingInfo_3dgwjtRgjp_cSb_lWrS5UW"
};