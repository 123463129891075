import * as React from 'react';
import { PersonCard } from '@microsoft/mgt-react/dist/es6/spfx';
import { Helper } from '../../Helper';
import { ITenantSettings } from '../../interfaces/ITenantSettings';

export interface IMSPersonCardProps {
  userLoginName: string;
  highlightColor?: string;
  userProfileImage?: string;
}

export interface IMSPersonCardState {
  id?: string;
  showPersonCard?: boolean;
  personCardTop?: number;
  isCardIntersectingWindow?: boolean;
  openedWith?: "click" | "hover";
  isEnabled?: boolean;
}

declare global {
  interface Window {
    isPersonCardShowing?: boolean;
  }
}

export class MSPersonCard extends React.Component<IMSPersonCardProps, IMSPersonCardState> {
  private readonly defaultColor = "#0078d4";
  private readonly defaultHoverColor = "#004578";
  private showCardTimeOutId: NodeJS.Timeout;
  private hideCardTimeOutId: NodeJS.Timeout;
  private readonly cardHeight = 523;

  constructor(props: IMSPersonCardProps) {
    super(props);
    this.state = {
      id: Helper.getRandomStringKey(),
      showPersonCard: false,
      isEnabled: false
    }
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  componentDidMount(): void {
    const tenantSettings = localStorage.getItem("IA_tenantSettings");
    if (tenantSettings) {
      const parsedSettings = JSON.parse(tenantSettings) as { value: ITenantSettings, date: string };
      if (parsedSettings?.value?.admin?.enableGraphPersonCard) {
        this.setState({ isEnabled: true });
      }
    }
  }

  private startHoverDelay(mouseY: number, openedWith: "click" | "hover") {
    if (!this.state.showPersonCard && !window.isPersonCardShowing) {
      this.showCardTimeOutId = setTimeout(() => {
        this.showPersonCard(mouseY, openedWith);
      }, 750);
    }
  }

  private showPersonCard(mouseY: number, openedWith: "click" | "hover") {
    let personCardTop = mouseY - 25;
    const cardHeight = this.cardHeight;
    if (personCardTop + cardHeight > window.innerHeight) {
      personCardTop = window.innerHeight - cardHeight - 50;
    }
    this.setState({ showPersonCard: true, personCardTop, openedWith }, () => {
      var popup = document.getElementById(this.state.id);
      if (popup.getBoundingClientRect().right > window.innerWidth) {
        this.setState({ isCardIntersectingWindow: true })
      }
    });
    window.isPersonCardShowing = true;
    window.addEventListener("mousedown", this.onClickHandler);
  }

  private onClickHandler(event: MouseEvent) {
    let element = event.target as HTMLElement;
    if (element.tagName !== "MGT-PERSON-CARD") {
      this.hideCard();
    }
  }

  private hideCard() {
    this.setState({ showPersonCard: false, personCardTop: undefined, openedWith: undefined, isCardIntersectingWindow: undefined }, () => {
      window.isPersonCardShowing = false;
      window.removeEventListener("mousedown", this.onClickHandler);
    });
  }

  public render(): JSX.Element {
    return (
      <>
        <div
          style={{ cursor: this.state.isEnabled && this.props.userLoginName ? "pointer" : "default" }}
          onClick={(event) => {
            if (this.state.isEnabled && !this.state.showPersonCard && !window.isPersonCardShowing) {
              const mouseY = event.clientY;
              this.showPersonCard(mouseY, "click");
            } 
          }}
          onMouseEnter={(event) => this.startHoverDelay(event.clientY, "hover")}
          onMouseMove={(event) => {
            if (this.state.isEnabled) {
              clearTimeout(this.showCardTimeOutId);
              this.startHoverDelay(event.clientY, "hover");
            }
          }}
          onMouseLeave={() => clearTimeout(this.showCardTimeOutId)}
        >
          {this.props.children}
        </div>
        {this.state.showPersonCard &&
          <div
            id={this.state.id}
            style={{
              position: "fixed",
              zIndex: 1000001,
              top: this.state.personCardTop,
              right: this.state.isCardIntersectingWindow ? 25 : undefined,
              ["--person-card-contact-link-color" as any]: this.props.highlightColor ?? this.defaultColor,
              ["--person-card-contact-link-hover-color" as any]: this.props.highlightColor ?? this.defaultHoverColor,
              ["--person-card-show-more-color" as any]: this.props.highlightColor ?? this.defaultColor,
              ["--person-card-show-more-hover-color" as any]: this.props.highlightColor ?? this.defaultHoverColor,
              ["--person-card-tab-nav-color" as any]: this.props.highlightColor ?? this.defaultColor
            }}
            onMouseEnter={() => clearTimeout(this.hideCardTimeOutId)}
            onMouseLeave={() => {
              if (this.state.openedWith === "hover") {
                this.hideCardTimeOutId = setTimeout(() => {
                  this.hideCard();
                }, 750);
              }
            }}
          >
            {/* Below div can be used as a placeholder when running locally */}
            {/* <div
              style={{
                height: this.cardHeight,
                width: 340,
                backgroundColor: "white",
                border: "1px solid",
                borderRadius: 5
              }}
            /> */}
            <PersonCard
              personQuery={this.props.userLoginName}
              isExpanded={true}
              personImage={this.props.userProfileImage}
              showPresence={true}
            />
          </div>
        }
      </>
    );
  }
}