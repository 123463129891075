exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_newComment_1Yho4tAJgG2p9QiP490f0y {\n  width: calc(100% - 10px);\n  height: auto;\n  position: relative;\n  float: left;\n  color: #333333;\n  margin-top: 5px;\n  margin-bottom: 20px;\n  margin-left: 10px;\n}\n\n\n.IA_postBtn_3pJhBpqUcvFEXOUCSAPlJE, .IA_cancelBtn_3C5suZaTGapvA7125EGDrJ {\n  margin-top: 5px;\n  float: right;\n}\n\n.IA_cancelBtn_3C5suZaTGapvA7125EGDrJ {\n  margin-right: 5px;\n}\n\n.IA_commentProfileImageWrapper_jen2AYmI52PlAhYvG3IPY {\n  height: 30px;\n  width: 30px;\n  float: left;\n  border-radius: 30px;\n  margin-right: 10px;\n  margin-top: 11px;\n  font-size: 15px;\n  text-align: center;\n  line-height: 29px;\n  text-transform: uppercase;\n  font-weight: 200;\n}\n\n.IA_commentProfileImagePicture_iULZFNG3yFX-X6qL2iMWr {\n  height: 32px;\n  width: 32px;\n  border-radius: 30px;\n  background-position: center;\n  background-size: cover;\n  margin-top: -32px;\n  left: -1px;\n  position: relative;\n  float: left;\n}\n\n.IA_commentProfileImageText_182hwDr228uujKTi_owLmp {\n  height: 30px;\n  width: 30px;\n  border-radius: 30px;\n  left: 0px;\n  top: 0px;\n  position: relative;\n  float: left;\n}\n\n.IA_commentReplyMobilePopup_31YfW4c1LSOCCJ6pix6h3o {\n  display: flex;\n  flex-direction: column;\n}\n\n.IA_commentReplyMobilePopup_31YfW4c1LSOCCJ6pix6h3o [class=\"fr-element fr-view\"] {\n  max-height: 125px;\n}", ""]);

// Exports
exports.locals = {
	"IA_newComment": "IA_newComment_1Yho4tAJgG2p9QiP490f0y",
	"IA_postBtn": "IA_postBtn_3pJhBpqUcvFEXOUCSAPlJE",
	"IA_cancelBtn": "IA_cancelBtn_3C5suZaTGapvA7125EGDrJ",
	"IA_commentProfileImageWrapper": "IA_commentProfileImageWrapper_jen2AYmI52PlAhYvG3IPY",
	"IA_commentProfileImagePicture": "IA_commentProfileImagePicture_iULZFNG3yFX-X6qL2iMWr",
	"IA_commentProfileImageText": "IA_commentProfileImageText_182hwDr228uujKTi_owLmp",
	"IA_commentReplyMobilePopup": "IA_commentReplyMobilePopup_31YfW4c1LSOCCJ6pix6h3o"
};