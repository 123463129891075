exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_likesAndComments_2g_-aMPuE_QkyFjwCylKOO {\n  float: left;\n  width: 100%;\n  font-size: 12px;\n  line-height: 20px;\n  margin-top: auto;\n  padding-bottom: 10px;\n  background-color: #f6f6f6;\n  margin-bottom: 20px;\n  box-sizing: border-box;\n}\n\n.IA_commandBar_2ObnxW3k2xZDneLRFliikw {\n  width: 100%;\n  height: 30px;\n  float: left;\n  box-sizing: border-box;\n  line-height: 30px;\n}\n\n.IA_commandBarButton_1Ig5cU4kCg3QTcK3NQ4Dby {\n  min-width: 40px;\n  padding-right: 10px;\n  float: left;\n  cursor: pointer;\n}\n\n.IA_commandBarButtonIcon_1VHTtj4HhZU17bRQrJOSdp {\n  margin-right: 5px;\n  margin-top: 1px;\n  float: left;\n}\n\n.IA_commandBarButtonText_1YhK4hD4g6JqKYCSaMjt8M {\n  float: left;\n  width: auto;\n  font-size: 14px;\n}\n\n.IA_comments_d5Y-jKw8PmD3ayDiD06m- {\n  width: 100%;\n  height: auto;\n  box-sizing: border-box;\n  clear: both;\n}\n\n.IA_likes_2doyrjMZVtr6nBVRYHZ-EC {\n  width: 100%;\n  box-sizing: border-box;\n}\n\n.IA_spinner_2hqRsuwBGN02UFLH4QDpOd {\n  -webkit-animation: spin_1JuhOPe8IpccT0QlPzfW69 700ms linear infinite;\n  -moz-animation: spin_1JuhOPe8IpccT0QlPzfW69 700ms linear infinite;\n  animation: spin_1JuhOPe8IpccT0QlPzfW69 700ms linear infinite;\n  height: 15px;\n  width: 15px;\n  background-position: center;\n  background-size: 15px;\n  position: relative;\n  float: left;\n  clear: both;\n  opacity: 0.5;\n}\n\n.IA_personWhoLiked_1LDtu2h9qT_aYN4GQ7sUZX {\n  padding-bottom: 5px;\n  padding-top: 5px;\n  border-bottom: 1px solid #eeeeee;\n  width: calc(100% - 15px);\n}\n\n@-moz-keyframes spin_1JuhOPe8IpccT0QlPzfW69 {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_1JuhOPe8IpccT0QlPzfW69 {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_1JuhOPe8IpccT0QlPzfW69 {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"IA_likesAndComments": "IA_likesAndComments_2g_-aMPuE_QkyFjwCylKOO",
	"IA_commandBar": "IA_commandBar_2ObnxW3k2xZDneLRFliikw",
	"IA_commandBarButton": "IA_commandBarButton_1Ig5cU4kCg3QTcK3NQ4Dby",
	"IA_commandBarButtonIcon": "IA_commandBarButtonIcon_1VHTtj4HhZU17bRQrJOSdp",
	"IA_commandBarButtonText": "IA_commandBarButtonText_1YhK4hD4g6JqKYCSaMjt8M",
	"IA_comments": "IA_comments_d5Y-jKw8PmD3ayDiD06m-",
	"IA_likes": "IA_likes_2doyrjMZVtr6nBVRYHZ-EC",
	"IA_spinner": "IA_spinner_2hqRsuwBGN02UFLH4QDpOd",
	"spin": "spin_1JuhOPe8IpccT0QlPzfW69",
	"IA_personWhoLiked": "IA_personWhoLiked_1LDtu2h9qT_aYN4GQ7sUZX"
};