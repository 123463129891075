exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_slides_3wQBOi5HT1Nw-qLeno7DHM {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_wraHhFuRBWmi6E7YxOdCZ {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_1ItSt25FgEydC_fV-NzZPq {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_3obtrIpUr7anoIQ9mD5sRA {\n  text-align: center;\n}", ""]);

// Exports
exports.locals = {
	"IA_slides": "IA_slides_3wQBOi5HT1Nw-qLeno7DHM",
	"IA_filters": "IA_filters_wraHhFuRBWmi6E7YxOdCZ",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_1ItSt25FgEydC_fV-NzZPq",
	"IA_paginationPageCount": "IA_paginationPageCount_3obtrIpUr7anoIQ9mD5sRA"
};