exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_weatherWidgetContainer_17V6I__V_lWyHvL0AV-xtg {\n  height:100%;\n  width:100%;\n  display:flex;\n  flex-direction:row;\n}\n\n.IA_weatherDay_24FPvivkKCWkJwZRdywjvZ {\n  flex:1;\n  display:flex;\n  flex-direction:column;\n  align-items:center;\n}\n\n.IA_iconAndPrecipationContainer_eNGe9VaVK1S56nGnlDfWv {\n  width:100%;\n  display:flex;\n  flex-direction:column;\n  align-items:center;\n}\n\n.IA_weatherIcon_1mjP9VQB0d3lbEqKxEdeY- {\n  width: 45%;\n}\n\n.IA_precipation_1SDO4_usxnwWiFwMyMGw9f {\n  color:#0062bf;\n  font-weight:bold;\n}\n\n.IA_graphContainer_3eydRmPniuVOLIXt5G7OkR {\n  width:100%;\n  height:30%;\n  position:absolute;\n  bottom:0;\n  left:0;\n}\n\n.IA_weatherWidgetContainerVertical_3EeuAzG2WDkf-D3kQnlarO {\n  height:100%;\n  width:100%;\n  background-color:#f0f2f4;\n  display:flex;\n  flex-direction: column;\n}\n\n.IA_weatherDayVertical_2QjkmKomMfGjtBeYtEwXom {\n  flex:1;\n  display:flex;\n  flex-direction:row;\n  align-items:center;\n  justify-content: space-around;\n}\n\n.IA_exampleWeatherLabel_22irt8EzjutwtVtqS0mZtt {\n  background-color: #ffffff;\n  color: #333333;\n  position: absolute;\n  text-align: center;\n  transform: rotate(-45deg);\n  box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px 0;\n}", ""]);

// Exports
exports.locals = {
	"IA_weatherWidgetContainer": "IA_weatherWidgetContainer_17V6I__V_lWyHvL0AV-xtg",
	"IA_weatherDay": "IA_weatherDay_24FPvivkKCWkJwZRdywjvZ",
	"IA_iconAndPrecipationContainer": "IA_iconAndPrecipationContainer_eNGe9VaVK1S56nGnlDfWv",
	"IA_weatherIcon": "IA_weatherIcon_1mjP9VQB0d3lbEqKxEdeY-",
	"IA_precipation": "IA_precipation_1SDO4_usxnwWiFwMyMGw9f",
	"IA_graphContainer": "IA_graphContainer_3eydRmPniuVOLIXt5G7OkR",
	"IA_weatherWidgetContainerVertical": "IA_weatherWidgetContainerVertical_3EeuAzG2WDkf-D3kQnlarO",
	"IA_weatherDayVertical": "IA_weatherDayVertical_2QjkmKomMfGjtBeYtEwXom",
	"IA_exampleWeatherLabel": "IA_exampleWeatherLabel_22irt8EzjutwtVtqS0mZtt"
};