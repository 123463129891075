exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_canteenMenuItem_3ID8pdGGU_Ap4c92MkdEpy {\n  width: 100%;\n  height: 100%;\n  position: relative;\n  overflow: hidden;\n}\n\n.IA_title_3DeqawtKAyINrGhP2cWqk1 {\n  float: left;\n  clear: both;\n  position: relative;\n  font-weight: bold;\n}\n\n.IA_contentWrapper_3CtYGZvDWICusDKSMy73KI {\n  float: left;\n  overflow: hidden;\n  width: 100%;\n  position: relative;\n}\n\n.IA_text_1YDiAF4irZabBOnlE3SQG6 {\n  float: left;\n  clear: both;\n}\n\n.IA_text_1YDiAF4irZabBOnlE3SQG6 p,\n.IA_text_1YDiAF4irZabBOnlE3SQG6 ul {\n  margin: 0;\n}\n\n.IA_progressBarBackground_3d0NEgPEeGGlD91gak3q2y {\n  position: relative;\n  float: left;\n  clear: both;\n  position: relative;\n}\n\n.IA_startDate_3UiyuLZtPXcwVHB0Jkm3Ao {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 10px; \n  font-weight: lighter;\n}\n\n.IA_byline_KcDK9bkBxL0SMXb4-N0Gk {\n  float: left;\n  clear: both;\n  position: relative;\n}", ""]);

// Exports
exports.locals = {
	"IA_canteenMenuItem": "IA_canteenMenuItem_3ID8pdGGU_Ap4c92MkdEpy",
	"IA_title": "IA_title_3DeqawtKAyINrGhP2cWqk1",
	"IA_contentWrapper": "IA_contentWrapper_3CtYGZvDWICusDKSMy73KI",
	"IA_text": "IA_text_1YDiAF4irZabBOnlE3SQG6",
	"IA_progressBarBackground": "IA_progressBarBackground_3d0NEgPEeGGlD91gak3q2y",
	"IA_startDate": "IA_startDate_3UiyuLZtPXcwVHB0Jkm3Ao",
	"IA_byline": "IA_byline_KcDK9bkBxL0SMXb4-N0Gk"
};