exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_listItem_3nqF3wJJ2exG9G-sn2WFzE {\n  position: relative;\n  width: 100%;\n  float: left;\n  box-sizing: border-box;\n}\n\n.IA_title_j-ivW7tNGjKVK91Xdv5J9 {\n  float: left;\n}\n\n.IA_teaser_3N1oyMmHXdyxVR5HeSDeW4 {\n  float: left;\n  display: -webkit-box;\n  box-orient: vertical;\n  -webkit-box-orient: vertical;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.IA_startDate_19gLfpGPoKBZsCE3BzrlTy {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 10px; \n}\n\n.IA_now_1AzRUQWCalc5wL9JfTrI6X {\n  float: left;\n  width: 100%;\n  text-align: center;\n  font-weight: bold;\n}\n\n.IA_startDateendDateDivider_1FK2KwAZb5UT14zdKl6brV {\n  float: left;\n  width: 60%;\n  height: 1px;\n  background-color: #999999;\n  margin-left: 20%;\n}\n\n.IA_endDate_2y6t7mRKIjSyeahDQmBiTv {\n  float: left;\n  width: 100%;\n  text-align: center;\n}\n\n.IA_coverOverlayToday_3tswkkS4sIZM7mXGZWfMNV {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 27px; \n  font-weight: bold;\n}\n\n.IA_coverOverlayMonth_1wWTYDunjHCIUfDoqPftMr {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 15px; \n  font-weight: normal;\n}\n\n.IA_coverOverlayDay_2qSTPWHv4ubOfjxgGzuv_4 {\n  float: left;\n  width: 100%;\n  text-align: center;\n}\n\n.IA_byline_1Uw1x5mukNf4VIkASEMPKW {\n  float: left;\n  clear: both;\n  position: relative;\n}\n", ""]);

// Exports
exports.locals = {
	"IA_listItem": "IA_listItem_3nqF3wJJ2exG9G-sn2WFzE",
	"IA_title": "IA_title_j-ivW7tNGjKVK91Xdv5J9",
	"IA_teaser": "IA_teaser_3N1oyMmHXdyxVR5HeSDeW4",
	"IA_startDate": "IA_startDate_19gLfpGPoKBZsCE3BzrlTy",
	"IA_now": "IA_now_1AzRUQWCalc5wL9JfTrI6X",
	"IA_startDateendDateDivider": "IA_startDateendDateDivider_1FK2KwAZb5UT14zdKl6brV",
	"IA_endDate": "IA_endDate_2y6t7mRKIjSyeahDQmBiTv",
	"IA_coverOverlayToday": "IA_coverOverlayToday_3tswkkS4sIZM7mXGZWfMNV",
	"IA_coverOverlayMonth": "IA_coverOverlayMonth_1wWTYDunjHCIUfDoqPftMr",
	"IA_coverOverlayDay": "IA_coverOverlayDay_2qSTPWHv4ubOfjxgGzuv_4",
	"IA_byline": "IA_byline_1Uw1x5mukNf4VIkASEMPKW"
};