exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_articles_3xJX3XdCLYah-mK2aBbgUZ {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_2Enlh72c_JsC9yEHl__dp6 {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_3inWRmNUQcoylneRNeg68O {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_2wtWRVvT6EhDuCcFwHqaxb {\n  text-align: center;\n}", ""]);

// Exports
exports.locals = {
	"IA_articles": "IA_articles_3xJX3XdCLYah-mK2aBbgUZ",
	"IA_filters": "IA_filters_2Enlh72c_JsC9yEHl__dp6",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_3inWRmNUQcoylneRNeg68O",
	"IA_paginationPageCount": "IA_paginationPageCount_2wtWRVvT6EhDuCcFwHqaxb"
};