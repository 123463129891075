exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_cardCover_1ENd_HPs4488yJPFUoasun {\n  background-color: #eeeeee;\n  width: 100%;\n  height: 144px;\n  background-position: center;\n  background-size: cover;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n}\n\n.IA_cardCover_1ENd_HPs4488yJPFUoasun iframe {\n  border: none;\n}\n\n/* Overlay image */\n\n.IA_coverOverlay_rGB2CmDKNUlvjh9f1XH-t {\n  width: 74px;\n  height: 74px;\n  background: rgba(255, 255, 255, 0.8);\n  position: absolute;\n  color: #333333;\n}\n\n.IA_startDate_2nfwyOeFadd_b4RPLBULZV {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 10px; \n  font-weight: lighter;\n  font-size: 14px;\n}\n\n.IA_startDateendDateDivider_3khhUQKSUEEdGkl0eilSpt {\n  float: left;\n  width: 60%;\n  height: 1px;\n  background-color: #999999;\n  margin-left: 20%;\n  margin-top: 10px;\n}\n\n.IA_endDate_3qbCPYjT1nqm8R9OlpS2bs {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 6px; \n  font-weight: lighter;\n  font-size: 14px;\n}\n\n.IA_coverOverlayToday_3Hc7L5SxkMm0mOzV9Csqdo {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 27px; \n  font-weight: bold;\n  font-size: 14px;\n}\n\n.IA_coverOverlayMonth_1LIgLvMXsF77jCMpd30Hal {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 15px; \n  font-weight: normal;\n  font-size: 14px;\n}\n\n.IA_coverOverlayDay_1EV1EUCZeZECAN2Q4tNidd {\n  float: left;\n  width: 100%;\n  text-align: center;\n  font-size: 22px;\n}\n", ""]);

// Exports
exports.locals = {
	"IA_cardCover": "IA_cardCover_1ENd_HPs4488yJPFUoasun",
	"IA_coverOverlay": "IA_coverOverlay_rGB2CmDKNUlvjh9f1XH-t",
	"IA_startDate": "IA_startDate_2nfwyOeFadd_b4RPLBULZV",
	"IA_startDateendDateDivider": "IA_startDateendDateDivider_3khhUQKSUEEdGkl0eilSpt",
	"IA_endDate": "IA_endDate_3qbCPYjT1nqm8R9OlpS2bs",
	"IA_coverOverlayToday": "IA_coverOverlayToday_3Hc7L5SxkMm0mOzV9Csqdo",
	"IA_coverOverlayMonth": "IA_coverOverlayMonth_1LIgLvMXsF77jCMpd30Hal",
	"IA_coverOverlayDay": "IA_coverOverlayDay_1EV1EUCZeZECAN2Q4tNidd"
};