exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".wordImagePasteErrorMessageContainer_1qAWhHqOszZbyRWKhz1qLp {\n  display: flex;\n  justify-content: center;\n}\n\n.wordImagePasteErrorMessage_9FQy8LwxThw3U0-7rrGZy {\n  display: flex;\n  justify-content: center;\n  background-color: orange;\n  border-radius: 5px;\n  transition: all .5s, height .75s;\n  width: 100%;\n  position: relative;\n  text-align: center;\n  align-items: center;\n  padding-left: 15px;\n  padding-right: 30px;\n}\n\n.editorHasFocus_2ZozJqJPWYA8gk7VDgEIQP [class*=\"fr-toolbar\"] {\nz-index: 3000000 !important;\n}\n\n.editorHasBlur_oEt5Qx-8gk77CJsaH3jCe [class*=\"fr-toolbar\"] {\nz-index: 1 !important;\n}", ""]);

// Exports
exports.locals = {
	"wordImagePasteErrorMessageContainer": "wordImagePasteErrorMessageContainer_1qAWhHqOszZbyRWKhz1qLp",
	"wordImagePasteErrorMessage": "wordImagePasteErrorMessage_9FQy8LwxThw3U0-7rrGZy",
	"editorHasFocus": "editorHasFocus_2ZozJqJPWYA8gk7VDgEIQP",
	"editorHasBlur": "editorHasBlur_oEt5Qx-8gk77CJsaH3jCe"
};