exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_playlists_1-R_J9VFsjGj8NV3r8Eqzk {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_1xTaBFWznj7EmHich4Gz3D {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_2NKjG0S9pfrO9pYEFElAy8 {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_SlD_r3AklNjHM2JSYt2A3 {\n  text-align: center;\n}", ""]);

// Exports
exports.locals = {
	"IA_playlists": "IA_playlists_1-R_J9VFsjGj8NV3r8Eqzk",
	"IA_filters": "IA_filters_1xTaBFWznj7EmHich4Gz3D",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_2NKjG0S9pfrO9pYEFElAy8",
	"IA_paginationPageCount": "IA_paginationPageCount_SlD_r3AklNjHM2JSYt2A3"
};