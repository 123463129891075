exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".likesAndComments_2YMQ6PKgX0NbcKj6iFAbzR {\n  position: relative;\n  float: right;\n  font-size: 14px;\n  line-height: 20px;\n  margin-top: 8px;\n}\n\n.likesAndComments_2YMQ6PKgX0NbcKj6iFAbzR .likes_3szJpQYsDil7rU0nPx3IjG {\n  height: 20px;\n  float: right;\n  position: relative;\n  cursor: pointer;\n}\n\n.likesAndComments_2YMQ6PKgX0NbcKj6iFAbzR .spinner_1FovVPD45vo8utKCl9oeqw {\n  height: 14px;\n  width: 12px;\n  float: right;\n  position: relative;\n  margin-left: 2px;\n  -webkit-animation: spin_2QNg4MwLgj5U8Yj6mBDPOo 700ms linear infinite;\n  -moz-animation: spin_2QNg4MwLgj5U8Yj6mBDPOo 700ms linear infinite;\n  animation: spin_2QNg4MwLgj5U8Yj6mBDPOo 700ms linear infinite;\n}\n\n.likesAndComments_2YMQ6PKgX0NbcKj6iFAbzR .likesText_1J3F1A6vgNrkjbLZbmkipg {\n  float: left;\n  margin-left: 4px;\n  margin-top: -1px;\n}\n\n.likesAndComments_2YMQ6PKgX0NbcKj6iFAbzR .likesTextVertical_Dt5hNK57QNGRYezIlbBT {\n  float: left;\n  clear: both;\n  margin-top: -7px;\n  text-align: center;\n  width: 100%;\n  box-sizing: border-box;\n  padding-left: 1px;\n}\n\n@-moz-keyframes spin_2QNg4MwLgj5U8Yj6mBDPOo {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_2QNg4MwLgj5U8Yj6mBDPOo {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_2QNg4MwLgj5U8Yj6mBDPOo {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"likesAndComments": "likesAndComments_2YMQ6PKgX0NbcKj6iFAbzR",
	"likes": "likes_3szJpQYsDil7rU0nPx3IjG",
	"spinner": "spinner_1FovVPD45vo8utKCl9oeqw",
	"spin": "spin_2QNg4MwLgj5U8Yj6mBDPOo",
	"likesText": "likesText_1J3F1A6vgNrkjbLZbmkipg",
	"likesTextVertical": "likesTextVertical_Dt5hNK57QNGRYezIlbBT"
};