exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_personaCard_3rmCBhMHZSEaSL9ndlFCGF{\n  display:flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.IA_personaWrapper_2KcdBUjxNfkPTN2vgcZWkX {\n  font-size: 14px;\n  float: left;\n  display: block;\n  width: 100%;\n  padding: 8px 0;\n}\n\n.IA_personaWrapper_2KcdBUjxNfkPTN2vgcZWkX .IA_personaProfileImageWrapper_6vKNYCKfGXM72c2Gz31zi {\n  height: 30px;\n  width: 30px;\n  float: left;\n  border-radius: 30px;\n  margin-right: 7px;\n  margin-top: 1px;\n  font-size: 15px;\n  text-align: center;\n  line-height: 29px;\n  text-transform: uppercase;\n  font-weight: 200;\n}\n\n\n.IA_personaSmall_2f_inkL-iCm2VEsv6bq9VR .IA_personaProfileImageText_2aqYMwDq5lQGqSFlL6hVGb {\n  height: 30px;\n  width: 30px;\n  border-radius: 30px;\n  left: 0px;\n  top: 0px;\n  position: relative;\n  float: left;\n}\n\n\n.IA_personaUserPrincipalName_ARq4Mo85Y3Cl88CTIGVOM {\n  font-size: 9px;\n  opacity: 0.8\n}", ""]);

// Exports
exports.locals = {
	"IA_personaCard": "IA_personaCard_3rmCBhMHZSEaSL9ndlFCGF",
	"IA_personaWrapper": "IA_personaWrapper_2KcdBUjxNfkPTN2vgcZWkX",
	"IA_personaProfileImageWrapper": "IA_personaProfileImageWrapper_6vKNYCKfGXM72c2Gz31zi",
	"IA_personaSmall": "IA_personaSmall_2f_inkL-iCm2VEsv6bq9VR",
	"IA_personaProfileImageText": "IA_personaProfileImageText_2aqYMwDq5lQGqSFlL6hVGb",
	"IA_personaUserPrincipalName": "IA_personaUserPrincipalName_ARq4Mo85Y3Cl88CTIGVOM"
};