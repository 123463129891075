exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_gallery_3fq75Td474paxVf4GyiVO- {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n}\n\n.IA_image_sBYKNFrT3cUONQQUL9Nj_ {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  background-size: cover;\n  background-position: center;\n}\n\n.IA_imageWithSlidingTransition_3kBPVr89FlSj4x8FrVVk31 {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  float: left;\n  background-size: cover;\n  background-position: center;\n}\n\n.IA_thumbnails_15DPjQ0_epKny9tI0StmlR {\n  width: 100%;\n  position: absolute;\n  bottom: 0;\n  background-color: rgb(51,51,51);\n}\n\n.IA_thumbnailsContainer_z_k_DvzrU5Phf1kBqZZXJ {\n  position: relative;\n  height: 100%;\n  width: max-content;\n  transition: margin-left 500ms ease-in-out;\n}\n\n.IA_thumbnail_1fVJ9okN22FCZlRvKdMF0d {\n  width: 100px;\n  height: 100px;\n  float: left;\n  background-size: cover;\n  background-position: center;\n  transition: opacity 500ms ease-in-out;\n}", ""]);

// Exports
exports.locals = {
	"IA_gallery": "IA_gallery_3fq75Td474paxVf4GyiVO-",
	"IA_image": "IA_image_sBYKNFrT3cUONQQUL9Nj_",
	"IA_imageWithSlidingTransition": "IA_imageWithSlidingTransition_3kBPVr89FlSj4x8FrVVk31",
	"IA_thumbnails": "IA_thumbnails_15DPjQ0_epKny9tI0StmlR",
	"IA_thumbnailsContainer": "IA_thumbnailsContainer_z_k_DvzrU5Phf1kBqZZXJ",
	"IA_thumbnail": "IA_thumbnail_1fVJ9okN22FCZlRvKdMF0d"
};