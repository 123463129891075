exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "\n.IA_Checkbox_3EXnpzuBWP5e6LU-IxI1TS {\n  display: -webkit-inline-box;\n}\n\n.IA_Checkbox_3EXnpzuBWP5e6LU-IxI1TS input {\n  position: absolute;\n  opacity: 0;\n  cursor: pointer;\n  height: 0;\n  width: 0;\n  background-color: #ffffff !important;\n}\n\n.IA_CheckboxInput_RL5ov1RR9hwG1_cMMhUV8{\n  margin-right: 7px;\n}\n\n.container_2S30x9daKJZB00swUsNANl {\n  display: block;\n  position: relative;\n  padding-left: 25px;\n  cursor: pointer;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n\n.checkmark_3OZ8FV-Uf6hNWCDKjJTk2Z {\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 18px;\n  width: 18px;\n  border-radius: 2px;\n  background-color: #cccccc;\n}\n\n.IA_dot_I0yyzkVNE8uP3sZJ0Em3B {\n  border-radius: 2px;\n  width: 9px;\n  height: 9px;\n  margin: 5px;\n  box-sizing: border-box;\n}\n", ""]);

// Exports
exports.locals = {
	"IA_Checkbox": "IA_Checkbox_3EXnpzuBWP5e6LU-IxI1TS",
	"IA_CheckboxInput": "IA_CheckboxInput_RL5ov1RR9hwG1_cMMhUV8",
	"container": "container_2S30x9daKJZB00swUsNANl",
	"checkmark": "checkmark_3OZ8FV-Uf6hNWCDKjJTk2Z",
	"IA_dot": "IA_dot_I0yyzkVNE8uP3sZJ0Em3B"
};