import * as React from 'react';
import * as styles from './ColorPicker.css';
import { IAIcon } from '../icon/Icon';
import { Helper } from '../../Helper';
import { CustomPicker } from "react-color";
import { Hue, Saturation } from "react-color/lib/components/common";
import { ENTER_KEY } from "../../global/Constants";
import { IATooltip } from '../tooltip/Tooltip';

export interface IColorPickerProps {
  label?: string;
  color: any;
  swatches?: string[];
  allowAddingToSwatches?: boolean;
  showClearButton: boolean;
  defaultColor?: string;
  styles?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  darkMode?: boolean;
  borderRadius?: number;
  saveOnCloseOnly?: boolean;
  inTeams?: boolean;
  isMobile?: boolean;
  disabled?: boolean;
  toolTip?: JSX.Element;
  displayInline?: boolean;
  showOpacity?: boolean;
  onChange: (color: string) => void;
  addSwatch?: (color: string) => void;
  removeColor?: () => void;
  forcePopupLeftPosition?: number;
}

export interface IColorPickerState {
  showColorPicker: boolean;
  color: any;
  hex: string;
  showPicker: boolean;
  pickerPositionTop?: number,
  pickerPositionLeft?: number
  opacity: number;
}

export class IAColorPicker extends React.Component<IColorPickerProps, IColorPickerState> {

  constructor(props: IColorPickerProps) {
    super(props);
    this.state = {
      showColorPicker: false,
      color: props.color === "transparent" ? undefined : props.color,
      hex: props.color,
      showPicker: false,
      opacity: this.getOpacity(props.color)
    };
  }

  private onChange(color): void {
    color = color && color != "transparent" ? color?.substring(0, 7) : color;
    this.setState({ color });
    this.copyColor(color);
    if (!this.props.saveOnCloseOnly) {
      if (this.props.showOpacity && this.state.opacity !== 0 && this.state.opacity !== 1) {
        this.props.onChange(this.addAlpha(color, this.state.opacity));
      } else {
        this.props.onChange(color);
      }
    }
  }

  private getOpacity(hex: string): number {
    let a: any = 1;
    if (hex?.includes("#") && hex?.length > 7) {
      a = "0x" + hex[7] + hex[8];
      a = +(a / 255).toFixed(3);
    }
    return a;
  }

  private addAlpha(color, opacity): string {
    // coerce values so it is between 0 and 1.
    var _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  public componentWillReceiveProps(props: IColorPickerProps): void {
    this.setState({ color: props.color === "transparent" ? undefined : props.color });
  }

  private copyColor(color: string): void {
    navigator.clipboard.writeText(color);
  }

  private isNewSwatch(): boolean {
    if (this.props.swatches == undefined) {
      return true;
    }
    if (this.props.swatches?.length === 0) {
      return true;
    }
    if (this.props.swatches?.length > 0) {
      let isNewSwatch = true
      this.props.swatches.forEach((swatch: string) => {
        if (swatch === this.state.color) {
          isNewSwatch = false;
        }
      });
      return isNewSwatch;
    }
  }

  public render(): JSX.Element {
    const swatches: JSX.Element[] = [];
    if (this.props.swatches?.length > 0) {
      this.props.swatches.forEach((color: string) => {
        swatches.push(
          <div
            className={styles.IA_colorPickerSwatch}
            style={{
              backgroundColor: color,
              borderColor: Helper.isLightColor(color) ? (this.props.darkMode ? color : "lightgray") : (this.props.darkMode ? "lightgray" : color)
            }}
            onClick={() => {
              this.setState({ opacity: 1 }, () => {
                this.onChange(color);
              })
            }}
          />
        );
      });
    }
    const EyeDropperComponent = () => {
      return (
        <>
          {/* @ts-ignore */}
          {window.EyeDropper?.prototype?.open &&
            <IAIcon
              title={"Color picker"}
              size={24}
              color={this.props.darkMode ? "#ffffff" : "#333333"}
              onClick={() => {
                // @ts-ignore
                const eyeDropper = new EyeDropper();
                eyeDropper.open().then((color) => {
                  if ((color?.sRGBHex as string)?.startsWith("#")) {
                    // sRGBHex is a hex: #000000
                    this.setState({ opacity: 1 }, () => {
                      this.onChange(color.sRGBHex);
                    });
                  } else {
                    // sRGBHex is rgb: rgb(0, 0, 0)
                    let rgb: string = color.sRGBHex?.slice(4);
                    rgb = rgb.slice(0, -1);
                    const rgbArray = rgb.split(", ");
                    if (rgbArray && rgbArray.length === 3) {
                      const hex = Helper.convertRgbToHex(parseInt(rgbArray[0]), parseInt(rgbArray[1]), parseInt(rgbArray[2]));
                      this.setState({ opacity: 1 }, () => {
                        this.onChange(hex);
                      });
                    }
                  }
                }).catch((e) => {
                  console.log(e);
                });
              }}
              style={{
                float: "left",
                cursor: "pointer",
                marginTop: 15,
                marginLeft: 10
              }}
            />
          }
        </>
      )
    }
    const MyPicker = ({ hsl, hsv, onChange }) => {
      let top = this.state.pickerPositionTop + 20;
      let left = this.state.pickerPositionLeft;
      if (document.getElementById("IA_picker")) {
        const pickerElement = document.getElementById("IA_picker");
        if (top + pickerElement.clientHeight > window.innerHeight) {
          top -= pickerElement.clientHeight + 50;
        }
        if (left + pickerElement.clientWidth > window.innerWidth) {
          left = window.innerWidth - (pickerElement.clientWidth + 20);
        }
      }
      return (
        <div
          id="IA_picker"
          style={{
            float: this.props.displayInline ? "left" : undefined,
            clear: this.props.displayInline ? "both" : undefined,
            width: 230,
            borderRadius: 5,
            overflow: "hidden",
            position: this.props.displayInline ? "unset" : "fixed",
            top: top,
            left: this.props.forcePopupLeftPosition !== undefined ? this.props.forcePopupLeftPosition : left,
            background: this.props.darkMode ? Helper.darkModeBackgroundColor : "#ffffff",
            border: "0px solid rgba(0, 0, 0, 0.25)",
            boxShadow: this.props.displayInline ? "" : "rgb(0 0 0 / 25%) 0px 1px 4px",
            zIndex: 3000000
          }}
        >
          <div
            style={{
              width: "100%",
              height: 100,
              position: "relative",
            }}
          >
            <Saturation
              hsl={hsl}
              hsv={hsv}
              onChange={onChange}
            />
          </div>
          <div
            style={{
              height: 10,
              position: "relative",
            }}
          >
            <Hue
              hsl={hsl}
              onChange={onChange}
            />
          </div>
          <div
            className={styles.IA_opacitySlider}
            style={{
              height: 15,
              position: "relative",
              width: "100%",
            }}
          >
            {this.props.showOpacity && this.state.color?.includes("#") &&
              <input
                type="range"
                min="0"
                max="100"
                value={this.state.opacity * 100}
                onChange={(event) => {
                  this.setState({ opacity: parseInt(event.target.value) / 100 }, () => this.onChange(this.state.color));
                }}
                style={{
                  width: "100%",
                  padding: 0,
                  backgroundImage: `linear-gradient(to right, ${Helper.convertHexWithOpacityToRgbaString(this.state.color.substring(0, 7), 0)}, ${Helper.convertHexWithOpacityToRgbaString(this.state.color.substring(0, 7), 1)})`
                }}
              />
            }
          </div>
          <div
            style={{
              width: "100%",
              padding: 10,
              boxSizing: "border-box",
              float: "left",
              height: "auto",
              borderRadius: "0 0 5px 5px"
            }}
          >
            <input
              id={"IAColorPickerInput"}
              value={this.state.color}
              type='text'
              style={{
                width: 100,
                fontFamily: "monospace",
                padding: "1px 10px",
                borderRadius: 5,
                background: this.props.darkMode ? Helper.darkModeInputFieldBackgroundColor : "#ffffff",
                color: this.props.darkMode ? "#ffffff" : "#333333",
                float: "left",
                marginLeft: 5
              }}
              onChange={(event) => {
                if (event.target.value?.length < 8 && event.target.value?.includes("#")) {
                  this.setState({ color: event.target.value }, () => {
                    document.getElementById("IAColorPickerInput").focus();
                  });
                }
              }}
              onKeyDown={(event) => {
                if (event.key === ENTER_KEY) {
                  event.stopPropagation();
                  this.setState({ showPicker: false });
                }
              }}
            />
            <EyeDropperComponent />
            {this.props.toolTip &&
              <div
                style={{
                  float: "right",
                  cursor: "pointer",
                  marginTop: 15,
                  marginRight: 8
                }}
              >
                <IATooltip
                  content="Tooltip"
                  type={this.props.isMobile ? "popup" : "bubble"}
                  styles={{
                    width: 200,
                    color: "#333333",
                  }}
                  componentContent={this.props.toolTip}
                >
                  <IAIcon
                    title={"Info"}
                    size={22}
                    color={this.props.darkMode ? "#eeeeee" : "#999999"}
                    onClick={() => { }}
                  />
                </IATooltip>
              </div>
            }
            <div
              style={{
                width: "100%",
                clear: "both"
              }}
            >
              {(this.props.allowAddingToSwatches || this.props.swatches != undefined) &&
                <div className={styles.IA_swatches}>
                  {swatches}
                  {this.props.allowAddingToSwatches && this.isNewSwatch() &&
                    <div
                      className={styles.IA_colorPickerAddSwatch}
                      style={{
                        // borderColor: this.state.color
                      }}
                    >
                      <IAIcon
                        title={"Add"}
                        size={22}
                        color={this.props.darkMode ? "#ffffff" : "#333333"}
                        onClick={() => this.props.addSwatch(this.state.color)}
                        style={{
                          float: "left",
                          cursor: "pointer",
                          marginLeft: 1
                        }}
                      />
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      );
    };
    const Picker = CustomPicker(MyPicker);
    return (
      <div
        className={this.props.darkMode ? styles.IA_colorPickerDarkMode : styles.IA_colorPicker}
        style={{
          ...this.props.styles,
          opacity: this.props.disabled ? 0.5 : 1
        }}
      >
        {this.props.label &&
          <label style={{
            color: this.props.darkMode ? Helper.darkModeLabelColor : undefined,
            ...this.props.labelStyle
          }}>
            {this.props.label}
          </label>
        }
        {this.props.displayInline
          ?
          <Picker
            color={this.state.color}
            onChange={(event) => this.onChange(event.hex)}
          />
          :
          <>
            <div
              className={styles.IA_colorPickerSwatchPreview}
              onClick={(event) => {
                if (!this.props.disabled) {
                  this.setState({ showPicker: true, pickerPositionTop: event.clientY, pickerPositionLeft: event.clientX, }, () => {
                    this.forceUpdate();
                  });
                }
              }}
              style={{
                backgroundColor: this.state.color,
                opacity: this.state.opacity,
                borderColor: Helper.isLightColor(this.state.color) ? (this.props.darkMode ? this.state.color : "lightgray") : (this.props.darkMode ? "#ffffff" : this.state.color),
              }}
            />
            {this.props.showClearButton && this.state.color !== "transparent" &&
              <div
                className={styles.IA_colorPickerClearButton}
                onClick={() => {
                  if (this.props.defaultColor) {
                    this.setState({ opacity: 1 }, () => {
                      this.onChange(this.props.defaultColor);
                    })
                  } else {
                    this.setState({ opacity: 1 }, () => {
                      this.onChange(undefined);
                    })
                  }
                }}
              >
                <IAIcon
                  title="Close"
                  size={16}
                  color={this.props.darkMode ? "#eeeeee" : "#333333"}
                />
              </div>
            }
            {this.props.removeColor &&
              <div
                className={styles.IA_colorPickerClearButton}
                onClick={() => {
                  this.props.removeColor();
                }}
              >
                <IAIcon
                  title="Close"
                  size={16}
                  color={this.props.darkMode ? "#eeeeee" : "#333333"}
                />
              </div>
            }

            {this.state.showPicker &&
              <>
                <div
                  style={{
                    width: "100vw",
                    height: "100vh",
                    position: "fixed",
                    backgroundColor: "transparent",
                    top: 0,
                    left: 0,
                    zIndex: 2999999
                  }}
                  onClick={() => {
                    this.props.onChange(this.state.color);
                    this.setState({ showPicker: false });
                  }}
                />
                <Picker
                  color={this.state.color}
                  onChange={(event) => this.onChange(event.hex)}
                />
              </>
            }
          </>
        }
      </div>
    );
  }
}