exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_footer_1M_rdLBSRr7IM2ioYTL2tP {\n  position: absolute;\n  width: 100%;\n  left: 0\n}\n\n.IA_footerWeatherAndClockContainer_39Z9eMuPFwaiF8RcmM7Lw_ {\n  height: 100%;\n  display: flex;\n  flex-direction: row-reverse;\n}\n\n.IA_logo_CS4fWcTcqiAH0H325eCLn {\n  height: 100%;\n  width: 50%;\n  background-repeat: no-repeat;\n  background-size: contain;\n  position: relative;\n  float: left;\n}\n\n.IA_clock_39nJaohkjLf3mBHPNqq01y {\n  height: 100%;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-end;\n}\n\n.IA_time_1qStr7pYeeC2XmJDLG7QpC {\n  position: relative;\n  float: right;\n  text-align: left;\n  margin-left: 19%;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_timeDivider_2bLczzrkSvVFHalLZwkjfV {\n  margin: 0 2px 0 2px;\n}\n\n.IA_date_3mNGBsJQ-foiG8jSakI7iQ {\n  position: relative;\n  float: right;\n  text-align: right;\n  font-weight: lighter;\n}\n\n\n.IA_weather_ht5Lwm22y8UBA0sfWFMyU {\n  height: 100%;\n  position: relative;\n  float: right;\n  box-sizing: border-box;\n}\n\n.IA_weatherIcon_xiacElS5uGXY42VeGhuhS {\n  position: relative;\n  text-align: left;\n}\n\n.IA_weatherTemperature_3Ko-BUGWJktifFJC5CDlKC {\n  position: relative;\n  float: right;\n  text-align: left;\n  width: 50%;\n\n}\n\n.IA_weatherArea_27XUfZzkbF7AhkuncoqMZL {\n  position: relative;\n  float: right;\n  text-align: left;\n  font-weight: lighter;\n  width: 50%;\n  clear: right;\n\n}", ""]);

// Exports
exports.locals = {
	"IA_footer": "IA_footer_1M_rdLBSRr7IM2ioYTL2tP",
	"IA_footerWeatherAndClockContainer": "IA_footerWeatherAndClockContainer_39Z9eMuPFwaiF8RcmM7Lw_",
	"IA_logo": "IA_logo_CS4fWcTcqiAH0H325eCLn",
	"IA_clock": "IA_clock_39nJaohkjLf3mBHPNqq01y",
	"IA_time": "IA_time_1qStr7pYeeC2XmJDLG7QpC",
	"IA_timeDivider": "IA_timeDivider_2bLczzrkSvVFHalLZwkjfV",
	"IA_date": "IA_date_3mNGBsJQ-foiG8jSakI7iQ",
	"IA_weather": "IA_weather_ht5Lwm22y8UBA0sfWFMyU",
	"IA_weatherIcon": "IA_weatherIcon_xiacElS5uGXY42VeGhuhS",
	"IA_weatherTemperature": "IA_weatherTemperature_3Ko-BUGWJktifFJC5CDlKC",
	"IA_weatherArea": "IA_weatherArea_27XUfZzkbF7AhkuncoqMZL"
};