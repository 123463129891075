exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_images_2C-gSIV7_L8LxgvkFExGrV {\n  width: 100%;\n  float: left;\n  position: relative;\n  margin-bottom: 20px;\n}\n\n.IA_imageRow_SmjyUv9zU_5PEp0YiDpmI {\n  width: 100%;\n  flex-flow: row;\n  justify-content: space-between;\n  align-items: flex-start;\n  float: left;\n  position: relative;\n  margin-top: 10px;\n}\n\n.IA_thumbnail_2K3Bb2tspiQn0qtsx8oj7g {\n  height: 100%;\n  background-position: center;\n  background-size: cover;\n  background-repeat: no-repeat;\n  position: relative;\n  margin: 5px;\n  float: left;\n  cursor: pointer;\n  border-radius: 5px;\n}\n\n.IA_imageResultInfo_iF0PdkysXu2ZteEmOWHwg {\n  width: 100%;\n  margin-top: 20px;\n  text-align: center;\n  color: gray;\n}\n\n\n.IA_spinner_2ny65BZkk90fzIqlK5Me3o {\n  height: 20px;\n  width: 20px;\n  margin-left: calc(50% - 10px);\n  margin-top: 20px;\n  -webkit-animation: spin_3vaMgifzKDyuJAo59SNA4V 1s linear infinite;\n  -moz-animation: spin_3vaMgifzKDyuJAo59SNA4V 1s linear infinite;\n  animation: spin_3vaMgifzKDyuJAo59SNA4V 1s linear infinite;\n  float: left;\n  position: relative;\n}\n\n@-moz-keyframes spin_3vaMgifzKDyuJAo59SNA4V {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_3vaMgifzKDyuJAo59SNA4V {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_3vaMgifzKDyuJAo59SNA4V {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n\n", ""]);

// Exports
exports.locals = {
	"IA_images": "IA_images_2C-gSIV7_L8LxgvkFExGrV",
	"IA_imageRow": "IA_imageRow_SmjyUv9zU_5PEp0YiDpmI",
	"IA_thumbnail": "IA_thumbnail_2K3Bb2tspiQn0qtsx8oj7g",
	"IA_imageResultInfo": "IA_imageResultInfo_iF0PdkysXu2ZteEmOWHwg",
	"IA_spinner": "IA_spinner_2ny65BZkk90fzIqlK5Me3o",
	"spin": "spin_3vaMgifzKDyuJAo59SNA4V"
};