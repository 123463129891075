exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".likesAndComments_2Cq2qEz4cO20F2V2_a8emM {\n  position: relative;\n  float: right;\n  font-size: 14px;\n  line-height: 20px;\n}\n\n.likesAndComments_2Cq2qEz4cO20F2V2_a8emM .likes_3wJOEokNTsuPsk8tMsPU3d {\n  height: 20px;\n  float: right;\n  position: relative;\n}\n\n.likesAndComments_2Cq2qEz4cO20F2V2_a8emM .spinner_25V-Bm0mp5VX4F2fx51J8D {\n  height: 14px;\n  width: 12px;\n  float: right;\n  position: relative;\n  margin-left: 2px;\n  -webkit-animation: spin_2g_sF6GGnpzbDVSGHRAaeJ 700ms linear infinite;\n  -moz-animation: spin_2g_sF6GGnpzbDVSGHRAaeJ 700ms linear infinite;\n  animation: spin_2g_sF6GGnpzbDVSGHRAaeJ 700ms linear infinite;\n}\n\n.likesAndComments_2Cq2qEz4cO20F2V2_a8emM .likesText__WLwq3gawRNUuLIR8ZV7w {\n  float: left;\n  margin-left: 4px;\n  margin-top: -1px;\n}\n\n.likesAndComments_2Cq2qEz4cO20F2V2_a8emM .likesTextVertical_1wMehntsgCN2K2Ns-yOtBu {\n  float: left;\n  clear: both;\n  margin-top: -7px;\n  text-align: center;\n  width: 100%;\n  box-sizing: border-box;\n  padding-left: 1px;\n}\n", ""]);

// Exports
exports.locals = {
	"likesAndComments": "likesAndComments_2Cq2qEz4cO20F2V2_a8emM",
	"likes": "likes_3wJOEokNTsuPsk8tMsPU3d",
	"spinner": "spinner_25V-Bm0mp5VX4F2fx51J8D",
	"spin": "spin_2g_sF6GGnpzbDVSGHRAaeJ",
	"likesText": "likesText__WLwq3gawRNUuLIR8ZV7w",
	"likesTextVertical": "likesTextVertical_1wMehntsgCN2K2Ns-yOtBu"
};