exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_topnav_2IcoE-NF8cFtuS713PLgtA {\n  color: #333333;\n  width: 100%;\n  border-bottom: solid 1px #eaeaea;\n  /* SP Theme: \"DisabledLines\" */\n  height: 44px;\n  overflow: unset;\n  padding: 0 10px;\n  box-sizing: border-box;\n  position: sticky;\n  top: 0;\n  background-color: #ffffff;\n  overflow: hidden;\n  z-index: 1;\n}\n\n.IA_topnav_outer_24kRHWkapBhqLmR6DCUF-u {\n  color: #333333;\n  width: 100%;\n  border-bottom: none;\n  /* SP Theme: \"DisabledLines\" */\n  height: 44px;\n  overflow: unset;\n  padding: 0 10px;\n  box-sizing: border-box;\n  position: sticky;\n  top: 0;\n  background-color: #eee;\n}\n\n.IA_instanceName_1zLmrv9ZTIZEMJDZtwQPES{\n  float: left;\n  font-size: 12px;\n}\n\n.IA_item_2io_B97diLewbs-izK-bSm {\n  float: right;\n  font-size: 11px;\n  height: 44px;\n  box-sizing: border-box;\n  margin-left: 14px;\n  padding-top: 7px;\n  user-select: none;\n}\n\n.IA_navigatorTitle_20oh4N_RquiSXZ4XP9a3s2 {\n  float: left;\n  margin-left: 30px;\n  line-height: 47px;\n  color: #777;\n  font-size: 11px;\n}", ""]);

// Exports
exports.locals = {
	"IA_topnav": "IA_topnav_2IcoE-NF8cFtuS713PLgtA",
	"IA_topnav_outer": "IA_topnav_outer_24kRHWkapBhqLmR6DCUF-u",
	"IA_instanceName": "IA_instanceName_1zLmrv9ZTIZEMJDZtwQPES",
	"IA_item": "IA_item_2io_B97diLewbs-izK-bSm",
	"IA_navigatorTitle": "IA_navigatorTitle_20oh4N_RquiSXZ4XP9a3s2"
};