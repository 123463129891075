exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_MentionsBox_1BCiWAAglMPgn4G3AJ4I_F {\n  position: fixed;\n  background-color: #ffffff;\n  border-radius: 5px;\n  box-sizing: border-box;\n  overflow: auto;\n  z-index: 3000001;\n  /* Below box shadow is the same as the other Froala popups */\n  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12); \n}\n\n.IA_MentionsSearchResultWrapper_3Crq8mS2lZRhJ09Rrblw2B {\n  display: flex;\n  flex-direction: row;\n  padding: 10px;\n  transition: background-color 0.1s;\n}\n\n.IA_MentionsSearchResultWrapper_3Crq8mS2lZRhJ09Rrblw2B:hover {\n  background-color: #eeeeee;\n  cursor: pointer;\n}\n\n.IA_MentionsSearchResultWrapper_3Crq8mS2lZRhJ09Rrblw2B:focus-visible {\n  background-color: #e3e3e3;\n  outline: none;\n}\n\n.IA_MentionsSearchResultInfo_lYOeAbX9U7rjM8ib02W36 {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  overflow: hidden;\n}\n\n.IA_MentionsSearchResultName_36kBAnjbJ6Q78-_CtSMznf, .IA_MentionsSearchResultUPN_2lvK2-FIHayMpxGENj9ThG {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.IA_MentionsSearchResultName_36kBAnjbJ6Q78-_CtSMznf {\n  font-size: 14px;\n}\n\n.IA_MentionsSearchResultUPN_2lvK2-FIHayMpxGENj9ThG {\n  font-size: 12px;\n  color: grey;\n}\n\n.IA_MentionsNoResult_3Tgbortu1LFrT_eUtwy_P7 {\n  margin: 10px;\n}", ""]);

// Exports
exports.locals = {
	"IA_MentionsBox": "IA_MentionsBox_1BCiWAAglMPgn4G3AJ4I_F",
	"IA_MentionsSearchResultWrapper": "IA_MentionsSearchResultWrapper_3Crq8mS2lZRhJ09Rrblw2B",
	"IA_MentionsSearchResultInfo": "IA_MentionsSearchResultInfo_lYOeAbX9U7rjM8ib02W36",
	"IA_MentionsSearchResultName": "IA_MentionsSearchResultName_36kBAnjbJ6Q78-_CtSMznf",
	"IA_MentionsSearchResultUPN": "IA_MentionsSearchResultUPN_2lvK2-FIHayMpxGENj9ThG",
	"IA_MentionsNoResult": "IA_MentionsNoResult_3Tgbortu1LFrT_eUtwy_P7"
};