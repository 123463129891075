exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_mediaFiles_3f7dPaJ4k2LIQu3edYc4KY {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_3vpY6jnEC4NHXlM-Mh-LMz {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_2RHIZ1JchYPUF_0bVAvzLB {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_2-FnjyweE6-fI0sHDAEyhi {\n  text-align: center;\n}\n\n.IA_tagButton_WHWdQGEzqdSHaGih5szUW {\n  padding: 5px 7px;\n  margin: 5px;\n  border-radius: 5px;\n  cursor: pointer;\n  user-select: none;\n  display: inline-block;\n  font-size: 13px;\n}\n\n.IA_tagButtonWithDelete_SmlI49JRe16TfeQrb9o1d {\n  margin: 5px;\n  border-radius: 5px;\n  cursor: pointer;\n  user-select: none;\n  display: inline-block;\n  font-size: 13px;\n  overflow: hidden;\n}\n\n.IA_tagButtonFlexContainer_2it23bHhlRiAkLS4BHB5vQ {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}\n\n.IA_tagButtonTagName_LorD4YvnoFh65_Za1doOi {\n  padding: 3px 7px 5px;\n}\n\n.IA_tagButtonIcon_1wAOzzS5iXLsHBRkFxgeLX {\n  padding: 3px 3px 5px;\n}\n\n.IA_tagButtonIcon_1wAOzzS5iXLsHBRkFxgeLX:hover{\n  background-color: rgba(0,0,0,0.2);\n}", ""]);

// Exports
exports.locals = {
	"IA_mediaFiles": "IA_mediaFiles_3f7dPaJ4k2LIQu3edYc4KY",
	"IA_filters": "IA_filters_3vpY6jnEC4NHXlM-Mh-LMz",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_2RHIZ1JchYPUF_0bVAvzLB",
	"IA_paginationPageCount": "IA_paginationPageCount_2-FnjyweE6-fI0sHDAEyhi",
	"IA_tagButton": "IA_tagButton_WHWdQGEzqdSHaGih5szUW",
	"IA_tagButtonWithDelete": "IA_tagButtonWithDelete_SmlI49JRe16TfeQrb9o1d",
	"IA_tagButtonFlexContainer": "IA_tagButtonFlexContainer_2it23bHhlRiAkLS4BHB5vQ",
	"IA_tagButtonTagName": "IA_tagButtonTagName_LorD4YvnoFh65_Za1doOi",
	"IA_tagButtonIcon": "IA_tagButtonIcon_1wAOzzS5iXLsHBRkFxgeLX"
};