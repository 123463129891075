exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_widgets_3D37HFfbNcoL7aXt8fpFTE {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_2-xScQlpGmKEsmddSJjz33 {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_397flnE3niI98REjFUc1_r {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_2VFBMhebB5vmA61nzk653F {\n  text-align: center;\n}", ""]);

// Exports
exports.locals = {
	"IA_widgets": "IA_widgets_3D37HFfbNcoL7aXt8fpFTE",
	"IA_filters": "IA_filters_2-xScQlpGmKEsmddSJjz33",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_397flnE3niI98REjFUc1_r",
	"IA_paginationPageCount": "IA_paginationPageCount_2VFBMhebB5vmA61nzk653F"
};