exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".likesAndComments_1sZiDYd5odtwCY70mrBi6b {\n  position: relative;\n  float: right;\n  height: 20px;\n  font-size: 14px;\n  line-height: 20px;\n  margin-top: 8px;\n}\n\n.likesAndComments_1sZiDYd5odtwCY70mrBi6b .likes_Bk5lkwdVQm1zmo7hdevKt {\n  height: 20px;\n  float: right;\n  position: relative;\n  margin-right: 5px;\n  cursor: pointer;\n}\n\n.likesAndComments_1sZiDYd5odtwCY70mrBi6b .spinner_2hVgeWb09dniAQ4byC14B9 {\n  height: 18px;\n  width: 18px;\n  float: right;\n  position: relative;\n  margin-left: 2px;\n  -webkit-animation: spin_HYXTredpTvnQVGESOZqOs 700ms linear infinite;\n  -moz-animation: spin_HYXTredpTvnQVGESOZqOs 700ms linear infinite;\n  animation: spin_HYXTredpTvnQVGESOZqOs 700ms linear infinite;\n}\n\n.likesAndComments_1sZiDYd5odtwCY70mrBi6b .comments_3_m685tdNRysPobMm9jDr3 {\n  height: 20px;\n  float: right;\n  position: relative;\n  margin-right: 3px;\n}\n\n.likesAndComments_1sZiDYd5odtwCY70mrBi6b .likesText_3eJUuQ_L8HZ6gA-A4OWEWe {\n  float: left;\n  margin-left: 5px;\n  margin-top: -1px;\n}\n\n.likesAndComments_1sZiDYd5odtwCY70mrBi6b .likesTextVertical_2Ob-o7WdjVMxcUOxwRYGBK {\n  float: left;\n  clear: both;\n  margin-top: -7px;\n  text-align: center;\n  width: 100%;\n  box-sizing: border-box;\n  padding-left: 1px;\n}\n\n.likesAndComments_1sZiDYd5odtwCY70mrBi6b .commentText_1L9lNMvbGjrLlSZ7vA6MIQ {\n  float: left;\n  margin-left: 4px;\n  margin-top: -2px;\n}\n\n@-moz-keyframes spin_HYXTredpTvnQVGESOZqOs {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_HYXTredpTvnQVGESOZqOs {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_HYXTredpTvnQVGESOZqOs {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"likesAndComments": "likesAndComments_1sZiDYd5odtwCY70mrBi6b",
	"likes": "likes_Bk5lkwdVQm1zmo7hdevKt",
	"spinner": "spinner_2hVgeWb09dniAQ4byC14B9",
	"spin": "spin_HYXTredpTvnQVGESOZqOs",
	"comments": "comments_3_m685tdNRysPobMm9jDr3",
	"likesText": "likesText_3eJUuQ_L8HZ6gA-A4OWEWe",
	"likesTextVertical": "likesTextVertical_2Ob-o7WdjVMxcUOxwRYGBK",
	"commentText": "commentText_1L9lNMvbGjrLlSZ7vA6MIQ"
};