exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_profile_2_ZFBM9gqstpxD9JA7c-MX {\n  width: 100%;\n  margin-top: 20px;\n}\n\n.IA_divider_CaZGRzZHCshzuAJ5hWYCi, .IA_dividerDarkMode_3hL9w295BJ6voUVJoEjDJ6 {\n  height: 1px;\n  width: 100%;\n  background-color: #eeeeee;\n  margin-top: 40px;\n  margin-bottom: 20px;\n  float: left;\n}\n\n.IA_dividerDarkMode_3hL9w295BJ6voUVJoEjDJ6 {\n  background-color: #a8a8a8;\n}\n\n.IA_widgetType_3bbCLh9Rr14uvrQrumfGw2 {\n  margin-bottom: 15px;\n  width: 75px;\n  cursor: pointer;\n}\n\n.IA_widgetTypeLabel_3t66UnaUiIw1qpv865VtXz {\n  font-size: 12px;\n  float: left;\n  clear: both;\n  width: 90%;\n  margin-top: 2px;\n}\n\n.IA_swatches_19COosFobkknMNvfpLbPN {\n  width: 100%;\n  clear: both;\n  position: relative;\n  float: left;\n}", ""]);

// Exports
exports.locals = {
	"IA_profile": "IA_profile_2_ZFBM9gqstpxD9JA7c-MX",
	"IA_divider": "IA_divider_CaZGRzZHCshzuAJ5hWYCi",
	"IA_dividerDarkMode": "IA_dividerDarkMode_3hL9w295BJ6voUVJoEjDJ6",
	"IA_widgetType": "IA_widgetType_3bbCLh9Rr14uvrQrumfGw2",
	"IA_widgetTypeLabel": "IA_widgetTypeLabel_3t66UnaUiIw1qpv865VtXz",
	"IA_swatches": "IA_swatches_19COosFobkknMNvfpLbPN"
};