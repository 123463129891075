exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Droid+Sans+Mono);", ""]);

// Module
exports.push([module.id, ".IA_counterBackground_emkeYsqrAe_kwkviYTlSm {\n  background-color: #4b234a;\n  background-size: cover;\n  background-position: center;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.IA_counterBackgroundOverlay_2FEk1a5uSkhTclkNCiLAYA {\n  background-color: #4b234a;\n  background-size: cover;\n  background-position: center;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.IA_content_3gmj9207zcgOS1ZjhMRqKN {\n  width: 100%;\n  position: absolute;\n  color: #ffffff;\n  font-family: \"Segoe UI\", \"Segoe UI Web (West European)\", \"Segoe UI\", -apple-system, BlinkMacSystemFont, Roboto, \"Helvetica Neue\", sans-serif;\n}\n\n.IA_headline_3vWO2LMPcWdmO4xyzJQHkX {\n  width: 100%;\n  text-align: center;\n  box-sizing: border-box;\n}\n\n.IA_description_3PRxB9rqWR7835l8x6n7yp {\n  width: 100%;\n  text-align: center;\n  box-sizing: border-box;\n}\n\n.IA_counter_2zwxcrBHRixLAuiAWDO09k {\n  display: flex;\n  flex-wrap: nowrap;\n  text-align: center;\n}\n\n.IA_days_1Zewr7FDDPvJAOtXMDCo2D {\n  text-align: center;\n  flex-direction: row;\n}\n\n.IA_hours_2dPs7SR7WSpaWN2l62xfAe {\n  text-align: center;\n  flex-direction: row;\n}\n\n.IA_minuts_32rxvmUFsogL53hM5kQeEc {\n  text-align: center;\n  flex-direction: row;\n}\n\n.IA_seconds_2osDjExODP4iUIQS87vcmT {\n  text-align: center;\n  flex-direction: row;\n}\n\n.IA_number_2V7ilFt821XP8HGCsgcdGv {\n  width: 100%;\n  font-family: \"Droid Sans Mono\", monospace;\n}\n\n.IA_numberInfo_2HaehYUGqcrv5xf8ew1IvD {\n  width: 100%;\n  font-weight: lighter;\n}", ""]);

// Exports
exports.locals = {
	"IA_counterBackground": "IA_counterBackground_emkeYsqrAe_kwkviYTlSm",
	"IA_counterBackgroundOverlay": "IA_counterBackgroundOverlay_2FEk1a5uSkhTclkNCiLAYA",
	"IA_content": "IA_content_3gmj9207zcgOS1ZjhMRqKN",
	"IA_headline": "IA_headline_3vWO2LMPcWdmO4xyzJQHkX",
	"IA_description": "IA_description_3PRxB9rqWR7835l8x6n7yp",
	"IA_counter": "IA_counter_2zwxcrBHRixLAuiAWDO09k",
	"IA_days": "IA_days_1Zewr7FDDPvJAOtXMDCo2D",
	"IA_hours": "IA_hours_2dPs7SR7WSpaWN2l62xfAe",
	"IA_minuts": "IA_minuts_32rxvmUFsogL53hM5kQeEc",
	"IA_seconds": "IA_seconds_2osDjExODP4iUIQS87vcmT",
	"IA_number": "IA_number_2V7ilFt821XP8HGCsgcdGv",
	"IA_numberInfo": "IA_numberInfo_2HaehYUGqcrv5xf8ew1IvD"
};