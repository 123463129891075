import * as React from 'react';
import * as styles from "./Panel.css";
import { IUser } from '../../interfaces/IUser';
import NavigatorBar from './navigationBar/NavigationBar'
import { Helper } from '../../Helper';
import { IALikesAndComments } from '../likesAndComments/LikesAndComments';
import { ILike } from '../../interfaces/ILike';
import IAAuthors from './authors/IAAuthors';
import { LocalizationService } from '../../services/LocalizationService';
import DateTimeService from '../../services/DateTimeService';
import { IInstance } from '../../interfaces/IInstance';
import { ESC_KEY_CODE } from '../../global/Constants';
import { Swipeable } from "react-swipeable";
import { Environment } from '../../services/Environment';
import { IAIcon } from '../icon/Icon';
import { IAAttachments } from '../attachments/Attachments';
import { IMessage, IMessageIAConfiguration } from '../../interfaces/IMessage';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';
import { IAImageViewer } from '../imageViewer/ImageViewer';
import { IComposerImage } from '../../interfaces/IComposerImage';
import { IASpinner } from '../spinner/Spinner';
import { IASkeleton } from '../skeleton/Skeleton';
import { ISkeletonElement } from '../../interfaces/ISkeletonElement';

export interface IPanelProps {
  showOuterPanel?: boolean;
  isOuterPanel?: boolean;
  showNavigationBar: boolean;
  title?: string;
  navigatorTitle?: string;
  navigatorTitleComponent?: JSX.Element;
  navigationsBarContent?: JSX.Element;
  isEvent?: boolean;
  startDate?: Date;
  endDate?: Date;
  allDayEvent?: boolean;
  open: boolean;
  transition: "slide" | "fade" | "none";
  width: number;
  headerImage?: string;
  headerVideo?: string;
  iaConfiguration?: IMessageIAConfiguration;
  highlightColor?: string;
  spinnerColor?: string;
  marginTop?: number;
  isMobile?: boolean;
  isFullPageView?: boolean;
  webpartId?: string;
  backgroundColor?: string;
  // Persona
  date?: string;
  authors?: IUser[];
  // Likes and Comments
  user?: IUser;
  userRole?: "Administrator" | "Editor" | "Reader";
  itemId?: string;
  isDraft?: boolean;
  tenant?: string;
  instance?: IInstance;
  token?: string;
  component?: "Messages" | "Event" | "ContentCreator" | "ContentOrganizer" | "AppAdmin" | "Permissions";
  environment?: "Development" | "Test" | "Production";
  source?: "Home" | "Mobile" | "SharePoint";
  commentsEnabled?: boolean;
  likesEnabled?: boolean;
  likesOnCommentsEnabled?: boolean;
  enableNext?: boolean;
  enablePrevious?: boolean;
  likes?: ILike[];
  commentCount?: number;
  commentOpenedFromLink?: string;
  disableSaving?: boolean;
  isUpdating?: boolean;
  dataAutomationIdPrefix?: string;
  loading?: boolean;
  skeletonLoaderElements?: ISkeletonElement[];
  showUniqueViews?: boolean;
  uniqueViewsCount?: number;
  overlayColor?: string;
  mobileCloseButton?: boolean;
  zIndex?: number;
  // Footer
  footer?: JSX.Element;
  // Callbacks
  close: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onCancel?: () => void;
  onSave?: () => void;
  onSaveAndClose?: () => void;
  onAddAndClose?: () => void;
  onSaveAsDraft?: () => void;
  onReviewReject?: () => void;
  onReviewApprove?: () => void;
  onReviewSave?: () => void;
  onClearFilters?: () => void;
  previousMessage?: () => void;
  nextMessage?: () => void;
  onCopyLink?: () => void;
  onEmailLink?: () => void;
  likesUpdated?: (likes: any[]) => void;
  commentsUpdated?: (comments: number) => void;
  onExportToCalender?: string;

  enableTranslation?: boolean;
  onTranslate?: (language: string, itemId: string) => void;
  isTranslating?: boolean;
  commentClicked?: () => void;
  commentCanceled?: () => void;
  addComment?: boolean;
  userScrolledToBottomOfContentArea?: () => void;

  isFirstResponder?: boolean;
  disableESCFunction?: boolean;

  enablePrint?: boolean;
  panelId?: string;
  printing?: boolean;
  printProgressPercentage?: number;
  printMessage?: () => void;
  isContentCreator?: boolean;

  imageViewerImages?: IComposerImage[];
  imageViewerIndex?: number;
  closeImageViewer?: () => void;

  approvers?: any[];

  isOldPanel?: boolean;
  darkMode?: boolean;
  isInTeams?: boolean;

  // Comments callbacks
  onCommentOpenIAMessage?: (instance: string, messageId: string) => void;
  onCommentContentImageClicked?: (imageUrl) => void;

  height?: number;
  openedFromAnotherMessage?: IMessage;
}

export interface IPanelState {
  likes?: ILike[];
  commentCount?: number;
  addComment: boolean;
  showOuterPanel?: boolean;
  headerImageHasCORS?: boolean;
  appendedToBody: boolean;
  panelId: string;
}

export class IAPanel extends React.Component<IPanelProps, IPanelState> {

  private readonly dateTimeService: DateTimeService = new DateTimeService();
  private readonly localizationService: LocalizationService = new LocalizationService();
  private ref = React.createRef<any>();
  private imgRef = React.createRef<any>();

  constructor(props: IPanelProps) {
    super(props);
    this.state = {
      addComment: props.addComment ? props.addComment : false,
      likes: props.likes,
      commentCount: props.commentCount,
      showOuterPanel: props.showOuterPanel,
      appendedToBody: false,
      panelId: this.props.panelId ? this.props.panelId : Helper.getRandomStringKey()
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentDidMount(): void {
    const appElement = document.getElementById("IA_App");
    const isAdmin = location.href.includes("intraactive-admin");
    if (appElement && !this.state.appendedToBody && !isAdmin) {
      this.setState({ appendedToBody: true });
      setTimeout(() => {
        const panel = document.getElementById(`panelId_${this.state.panelId}`);
        if (panel) {
          document.body.appendChild(panel);
        }
      }, 500);
    }
    document.addEventListener("keydown", (event) => this.escFunction(event), false);
  }

  public componentWillUnmount(): void {
    document.removeEventListener("keydown", (event) => this.escFunction(event), false);
  }

  private hidePanel(): void {
    if (!this.props.isInTeams) {
      document.body.style.overflowY = "auto";
    }
    document.body.style.height = "inherit";
  }

  public componentWillReceiveProps(props: IPanelProps): void {
    if (props.showOuterPanel != this.props.showOuterPanel) {
      this.setState({ showOuterPanel: props.showOuterPanel });
    }
    if (props.likes != this.props.likes) {
      this.setState({ likes: props.likes });
    }
    if (props.commentCount != this.props.commentCount) {
      this.setState({ commentCount: props.commentCount });
    }
    if (props.addComment !== this.props.addComment) {
      this.setState({ addComment: props.addComment })
    }
    if (props.open) {
      document.body.style.overflowY = "hidden";
      document.body.style.height = "100vh";
    }
  }

  private onScroll(): void {
    if (this.props.userScrolledToBottomOfContentArea != undefined) {
      let pageDiv = document.getElementById(`slimPanelContentArea-${this.state.panelId}`);
      if (Math.ceil(pageDiv.offsetHeight + pageDiv.scrollTop) >= pageDiv.scrollHeight) {
        this.props.userScrolledToBottomOfContentArea();
      }
    }
  }

  private escFunction(event: KeyboardEvent): void {
    if (event.keyCode === ESC_KEY_CODE && !this.props.disableESCFunction && (this.props.isFirstResponder || this.props.isFirstResponder === undefined)) {
      this.props.onCancel != undefined ? this.props.onCancel() : {};
      this.props.close != undefined ? this.props.close() : {};
      this.hidePanel();
    }
  }

  private onPinchZoom(pinch: { scale: number, x: number, y: number }): void {
    const { current: img } = this.imgRef;
    if (img) {
      const x = pinch.x;
      const y = pinch.y;
      const scale = pinch.scale;
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty("transform", value);
    }
  }

  public render(): JSX.Element {
    const showImage = this.props.headerVideo == undefined || (this.props.headerVideo.indexOf("sharepoint.com") != -1 && localStorage.getItem("loginType") != undefined);
    const keepHeaderImageWhenPrinting = this.props.printing && this.state.headerImageHasCORS;
    const removeHeaderImageWhenPrinting = this.props.printing && !this.state.headerImageHasCORS; // used to show error message when printing images that has no CORS support
    const headerHeight = removeHeaderImageWhenPrinting ? 50 : this.props.isMobile ? ((window.innerWidth / 16) * 9) : 337;

    let image: string;
    if (!this.props.iaConfiguration?.cropSettings) {
      image = this.props.headerImage;
    } else {
      image = Helper.getImageUrl(Environment.getEnvironmentForImageAPI(this.props.environment), this.props.tenant, this.props.token, this.props.headerImage, undefined, "StandardLarge", this.props.iaConfiguration?.cropSettings);;
    }

    let altText = this.props.iaConfiguration && this.props.iaConfiguration.headerImageAltText ? this.props.iaConfiguration.headerImageAltText : "";
    let altVideoText = this.props.iaConfiguration && this.props.iaConfiguration.headerVideoAltText ? this.props.iaConfiguration.headerVideoAltText : "";

    let isEventToday;
    let isOneDayEvent;
    if (this.props.startDate != undefined) {
      isEventToday = this.dateTimeService.ConvertToDDMMYYYY(this.props.startDate) === this.dateTimeService.ConvertToDDMMYYYY(new Date()) && this.props.endDate == undefined || this.props.endDate != undefined && this.dateTimeService.ConvertToDDMMYYYY(this.props.startDate) === this.dateTimeService.ConvertToDDMMYYYY(new Date()) && this.dateTimeService.ConvertToDDMMYYYY(this.props.endDate) === this.dateTimeService.ConvertToDDMMYYYY(new Date());
      isOneDayEvent = this.props.endDate == undefined || this.props.endDate != undefined && this.dateTimeService.ConvertToDDMMYYYY(this.props.endDate) === this.dateTimeService.ConvertToDDMMYYYY(this.props.startDate);
    }
    let style: React.CSSProperties;
    let printPanelWidth;
    const transition = "all 200ms ease-in-out";
    if (this.state.showOuterPanel && !this.props.isOuterPanel) {
      printPanelWidth = this.props.isMobile ? window.innerWidth : this.props.width;
      style = {
        right: this.props.open ? 360 : location.href.toLowerCase().indexOf("intraactive-app") !== -1 || location.href.toLowerCase().indexOf("mobile-spa") !== -1 || location.href.toLowerCase().indexOf("localhost") ? -(this.props.width + 50) : -(window.innerWidth + 50),
        transition: transition,
        width: printPanelWidth,
        marginTop: this.props.marginTop != undefined ? this.props.marginTop : 0,
        height: this.props.height != undefined ? this.props.height : (this.props.marginTop != undefined ? `calc(100% - ${this.props.marginTop}px` : "100%")
      }
    }
    else {
      printPanelWidth = this.props.isMobile ? window.innerWidth : this.props.width;
      style = {
        right: this.props.open
          ? 0
          : location.href.toLowerCase().indexOf("intraactive-app") !== -1 || location.href.toLowerCase().indexOf("mobile-spa") !== -1 || location.href.toLowerCase().indexOf("localhost") !== -1
            ? -(this.props.width * 2)
            : -(window.innerWidth * 2),
        transition: transition,
        width: this.props.isMobile ? window.innerWidth : this.props.width,
        marginTop: this.props.marginTop != undefined ? this.props.marginTop : 0,
        height: this.props.height != undefined ? this.props.height : (this.props.marginTop != undefined ? `calc(100% - ${this.props.marginTop}px` : "100%")
      }
    }

    if (style.width as number > window.innerWidth) {
      style.width = window.innerWidth;
    }

    const fullScreenPadding = this.props.printing ? 0 : "calc(50% - 400px)";
    const fullScreenWidth = this.props.printing ? 700 : 800;

    let backgroundColor = this.props.darkMode ? Helper.darkModeBackgroundColor : (this.props.isOuterPanel ? "#eee" : "#fff");
    if (this.props.backgroundColor) {
      backgroundColor = this.props.backgroundColor;
    }
    let heightToMinusFromContent = 0;
    if (this.props.showNavigationBar) {
      heightToMinusFromContent = heightToMinusFromContent + 44;
    }
    if (this.props.footer != undefined) {
      heightToMinusFromContent = heightToMinusFromContent + 50;
    }
    return (
      <div id={`panelId_${this.state.panelId}`}>
        <Swipeable
          onSwipedRight={this.props.previousMessage && this.props.enablePrevious ?
            () => {
              setTimeout(() => {
                this.props.previousMessage();
                document.querySelector("." + styles.slimPanelContentArea).scrollTop = 0;
              }, 400);
            } : undefined
          }
          onSwipedLeft={this.props.nextMessage && this.props.enableNext ?
            () => {
              setTimeout(() => {
                this.props.nextMessage();
                document.querySelector("." + styles.slimPanelContentArea).scrollTop = 0;
              }, 400);
            } : undefined
          }
        >
          {this.props.overlayColor !== "transparent" &&
            <div
              className={styles.panelWrapper}
              style={{
                visibility: this.props.open ? "visible" : "hidden",
                backgroundColor: this.props.overlayColor ? this.props.overlayColor : "rgba(255, 255, 255, 0.24)",
                zIndex: this.props.zIndex ? this.props.zIndex : 1000000
              }}
              onClick={() => {
                this.props.close();
                this.hidePanel();
              }}
            />
          }
          <div
            className={this.props.isOuterPanel ? styles.slimOuterPanel : (this.props.isFullPageView ? styles.fullscreenPanel : styles.slimPanel)}
            style={{
              zIndex: this.props.zIndex ? this.props.zIndex : 1000001,
              boxShadow: this.props.overlayColor === "transparent" ? "none" : "-5px 5px 10px 0 rgba(0, 0, 0, 0.24)",
              borderLeft: this.props.overlayColor === "transparent" ? "1px solid #eeeeee" : "none",
              backgroundColor: this.props.darkMode ? Helper.darkModeBackgroundColor : (this.props.isOuterPanel ? "#eee" : "#fff"),
              ...style
            }}
          >
            <div
              className={this.props.isFullPageView ? styles.fullscreenPanelContentArea : styles.slimPanelContentArea}
              id={`slimPanelContentArea-${this.state.panelId}`}
              onScroll={() => this.onScroll()}
              style={{
                backgroundColor: backgroundColor,
                top: this.props.showNavigationBar ? 44 : 0,
                height: this.props.printing ? "auto" : `calc(100% - ${heightToMinusFromContent}px)`,
                overflowY: this.props.printing ? "hidden" : "visible",
                paddingLeft: this.props.isFullPageView ? fullScreenPadding : undefined,
                paddingRight: this.props.isFullPageView ? fullScreenPadding : undefined,
                width: this.props.isFullPageView ? fullScreenWidth : "100%",
                color: this.props.darkMode ? "#ffffff" : "#333333",
                opacity: this.props.loading ? 0 : 1,
                transition: "opacity 200ms",
              }}
              ref={this.ref}
            >
              <div
                className={styles.header}
                style={{
                }}>
                {((this.props.headerImage != undefined && this.props.headerImage !== "") || this.props.headerVideo != undefined) &&
                  <div>
                    {this.props.headerVideo && (this.props.headerVideo.indexOf("microsoftstream") != -1 || (this.props.headerVideo.indexOf("sharepoint.com") != -1 && localStorage.getItem("loginType") != undefined)) && this.props.isMobile && !this.props.printing ?
                      <a href={this.props.headerVideo} target="_blank">
                        <div
                          className={styles.headerImage}
                          style={{
                            backgroundImage:
                              this.props.headerVideo == undefined || this.props.headerVideo.indexOf("microsoftstream") != -1 || (this.props.headerVideo.indexOf("sharepoint.com") != -1 && localStorage.getItem("loginType") != undefined)
                                ? `url(${this.props.headerImage})`
                                : undefined,
                            height: this.props.isMobile ? ((window.innerWidth / 16) * 9) : 337
                          }}
                        >
                          {this.props.isEvent && !isEventToday && !isOneDayEvent &&
                            <div className={styles.IA_coverOverlay}>
                              <div className={styles.IA_startDate}>{`${this.dateTimeService.getDayInMonth(this.props.startDate)}. ${this.dateTimeService.getNameOfMonth(this.props.startDate, this.props.allDayEvent)?.substring(0, 3)?.toUpperCase()}`}</div>
                              <div className={styles.IA_startDateendDateDivider} />
                              <div className={styles.IA_endDate}>{`${this.dateTimeService.getDayInMonth(this.props.endDate)}. ${this.dateTimeService.getNameOfMonth(this.props.endDate, this.props.allDayEvent)?.substring(0, 3)?.toUpperCase()}`}</div>
                            </div>
                          }
                          {this.props.isEvent && !isEventToday && isOneDayEvent &&
                            <div className={styles.IA_coverOverlay}>
                              <div className={styles.IA_coverOverlayMonth}>{this.dateTimeService.getNameOfMonth(this.props.startDate, this.props.allDayEvent)?.substring(0, 3)?.toUpperCase()}</div>
                              <div className={styles.IA_coverOverlayDay}>{this.dateTimeService.getDayInMonth(this.props.startDate)}</div>
                            </div>
                          }
                          {this.props.isEvent && isEventToday && isOneDayEvent &&
                            <div className={styles.IA_coverOverlay}>
                              <div className={styles.IA_coverOverlayToday}>{new LocalizationService().strings.DateTimeService_Today?.toUpperCase()}</div>
                            </div>
                          }
                          {(this.props.headerVideo && (this.props.headerVideo.indexOf("microsoftstream") != -1 || (this.props.headerVideo.indexOf("sharepoint.com") != -1 && localStorage.getItem("loginType") != undefined)) && this.props.isMobile) &&
                            <div
                              className={styles.headerImage}
                              style={{
                                backgroundImage: "url(https://intraactivestorage.blob.core.windows.net/cdn/video-thumbnail.png)",
                                height: "100%"
                              }}
                            />
                          }
                        </div>
                      </a>
                      :
                      <div
                        className={styles.headerImage}
                        style={{
                          height: headerHeight
                        }}
                      >

                        {((showImage && !this.props.isMobile && !this.props.printing) || keepHeaderImageWhenPrinting) &&
                          <img
                            src={image}
                            style={{ height: headerHeight, width: "100%", objectFit: "cover" }}
                            alt={altText}
                          />
                        }

                        {(showImage && this.props.isMobile) &&
                          <QuickPinchZoom onUpdate={(pinch) => this.onPinchZoom(pinch)}>
                            <img
                              ref={this.imgRef}
                              src={image}
                              style={{ height: headerHeight, width: "100%", objectFit: "cover" }}
                              alt={altText}
                            />
                          </QuickPinchZoom>
                        }

                        {this.props.headerVideo && !this.props.isEvent && !(this.props.isMobile && (this.props.headerVideo.indexOf("microsoftstream") != -1) || (this.props.headerVideo.indexOf("sharepoint.com") != -1 && localStorage.getItem("loginType") != undefined)) && this.props.headerVideo.indexOf("sharepoint.com") === -1 && !this.props.printing &&
                          <iframe
                            width="100%"
                            height="100%"
                            allowFullScreen
                            src={this.props.headerVideo}
                            title={altVideoText}
                          >
                          </iframe>
                        }
                        {this.props.headerVideo && !this.props.isEvent && !(this.props.isMobile && (this.props.headerVideo.indexOf("microsoftstream") != -1) || (this.props.headerVideo.indexOf("sharepoint.com") === -1 && localStorage.getItem("loginType") != undefined)) && this.props.headerVideo.indexOf("sharepoint.com") !== -1 && !this.props.printing &&
                          <video
                            height="100%"
                            width="100%"
                            controls
                            poster={image}
                            title={altVideoText}
                          >
                            <source src={this.props.headerVideo}></source>
                          </video>
                        }
                        {this.props.isEvent && !isEventToday && !isOneDayEvent &&
                          <div className={styles.IA_coverOverlay}>
                            <div className={styles.IA_startDate}>{`${this.dateTimeService.getDayInMonth(this.props.startDate)}. ${this.dateTimeService.getNameOfMonth(this.props.startDate, this.props.allDayEvent)?.substring(0, 3)?.toUpperCase()}`}</div>
                            <div className={styles.IA_startDateendDateDivider} />
                            <div className={styles.IA_endDate}>{`${this.dateTimeService.getDayInMonth(this.props.endDate)}. ${this.dateTimeService.getNameOfMonth(this.props.endDate, this.props.allDayEvent)?.substring(0, 3)?.toUpperCase()}`}</div>
                          </div>
                        }
                        {this.props.isEvent && !isEventToday && isOneDayEvent &&
                          <div className={styles.IA_coverOverlay}>
                            <div className={styles.IA_coverOverlayMonth}>{this.dateTimeService.getNameOfMonth(this.props.startDate, this.props.allDayEvent)?.substring(0, 3)?.toUpperCase()}</div>
                            <div className={styles.IA_coverOverlayDay}>{this.dateTimeService.getDayInMonth(this.props.startDate)}</div>
                          </div>
                        }
                        {this.props.isEvent && isEventToday && isOneDayEvent &&
                          <div className={styles.IA_coverOverlay}>
                            <div className={styles.IA_coverOverlayToday}>{new LocalizationService().strings.DateTimeService_Today?.toUpperCase()}</div>
                          </div>
                        }
                        {removeHeaderImageWhenPrinting &&
                          <div style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center" }}>
                            <p style={{ color: "grey" }}>{new LocalizationService().strings.Messages_ViewPanel_UnableToPrintImage}</p>
                          </div>
                        }
                      </div>
                    }
                  </div>
                }
                {this.props.title != undefined &&
                  <div className={styles.title}>
                    <h1>{this.props.title}</h1>
                  </div>
                }
                {this.props.date && this.props.authors && this.props.component === "Messages" &&
                  <div
                    style={{
                      float: "left",
                      marginBottom: 15,
                      fontSize: 13,
                      color: "#858585"
                    }}
                  >
                    {this.props.date}
                  </div>
                }
                {this.props.date != undefined && this.props.authors != undefined &&
                  <IAAuthors
                    highlightColor={this.props.highlightColor}
                    authors={this.props.authors}
                  />
                }
              </div>
              {this.props.children != undefined &&
                <div
                  className={styles.content}
                  style={{
                    opacity: this.props.loading ? 0 : 1,
                    transition: "opacity 200ms"
                  }}
                >
                  {this.props.children}
                  {this.props.iaConfiguration && this.props.isOldPanel &&
                    <IAAttachments
                      attachments={this.props.iaConfiguration.attachments}
                      linkColor={this.props.highlightColor}
                    />
                  }
                </div>
              }
              {this.props.itemId != undefined && !this.props.printing && this.props.open &&
                <IALikesAndComments
                  top={this.props.marginTop}
                  itemId={this.props.itemId}
                  tenant={this.props.tenant}
                  component={this.props.component === "Messages" ? "Message" : "Event"}
                  instance={this.props.instance}
                  token={this.props.token}
                  environment={this.props.environment}
                  user={this.props.user}
                  userRole={this.props.userRole}
                  imageUploadUrl={`${Helper.getMainAPIUrl(this.props.environment)}/api/${this.props.tenant}.sharepoint.com/image?relativeUrl=Lists/IAImages/Comments&site=IntraActive-Admin`}
                  color={this.props.highlightColor}
                  source={this.props.source}
                  likesEnabled={this.props.likesEnabled}
                  commentsEnabled={this.props.commentsEnabled}
                  likesOnCommentsEnabled={this.props.likesOnCommentsEnabled}
                  newCommentPopupIsShowing={() => { }}
                  likes={this.state.likes}
                  commentCount={this.state.commentCount}
                  isMobile={this.props.isMobile}
                  webpartId={this.props.webpartId}
                  commentOpenedFromLink={this.props.commentOpenedFromLink}
                  likesUpdated={(likes: any[]) => {
                    this.props.likesUpdated(likes);
                    this.setState({ likes }, () => this.forceUpdate());
                  }}
                  commentsUpdated={(comments: number) => {
                    this.props.commentsUpdated(comments);
                    this.setState({ commentCount: comments });
                  }}
                  style={{
                    marginBottom: 0,
                    opacity: this.props.loading ? 0 : 1,
                    transition: "opacity 200ms"
                  }}
                  padding={this.props.isMobile ? 20 : 40}
                  commentClicked={() => {
                    if (this.props.commentClicked) {
                      this.props.commentClicked();
                      var my_element = document.getElementById(`IALikesAndComments_${this.props.instance.instanceName}_${this.props.itemId}_${this.props.webpartId}`);
                      my_element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest"
                      });
                    }
                  }}
                  commentCanceled={() => {
                    if (this.props.commentCanceled) {
                      this.props.commentCanceled();
                    }
                  }}
                  onOpenIAMessage={(instance, messageId) => this.props.onCommentOpenIAMessage && this.props.onCommentOpenIAMessage(instance, messageId)}
                  onContentImageClicked={(imageUrl) => this.props.onCommentContentImageClicked && this.props.onCommentContentImageClicked(imageUrl)}
                />
              }
            </div>
            {this.props.showNavigationBar && this.props.open &&
              <NavigatorBar
                approvers={this.props.approvers}
                showUniqueViews={this.props.showUniqueViews}
                uniqueViewsCount={this.props.uniqueViewsCount}
                isOuterPanel={this.props.isOuterPanel}
                navigatorTitleComponent={this.props.navigatorTitleComponent}
                navigatorTitle={this.props.navigatorTitle}
                navigationsBarContent={this.props.navigationsBarContent}
                highlightColor={this.props.highlightColor}
                user={this.props.user}
                itemId={this.props.itemId}
                isDraft={this.props.isDraft}
                tenant={this.props.tenant}
                instance={this.props.instance}
                token={this.props.token}
                environment={this.props.environment}
                source={this.props.source}
                component={this.props.component}
                commentsEnabled={this.props.commentsEnabled}
                likesEnabled={this.props.likesEnabled}
                enableNext={this.props.enableNext}
                enablePrevious={this.props.enablePrevious}
                likes={this.state.likes}
                commentCount={this.state.commentCount}
                disableSaving={this.props.disableSaving}
                isUpdating={this.props.isUpdating}
                isMobile={this.props.isMobile}
                darkMode={this.props.darkMode}
                onEdit={this.props.onEdit ? () => {
                  this.props.onEdit();
                  this.hidePanel();
                } : undefined}
                onDelete={this.props.onDelete ? () => {
                  this.props.onDelete();
                  this.hidePanel();
                } : undefined}
                onCancel={this.props.onCancel ? () => {
                  this.props.onCancel();
                  this.hidePanel();
                } : undefined}
                onSaveAndClose={this.props.onSaveAndClose ? () => {
                  this.props.onSaveAndClose();
                  this.hidePanel();
                } : undefined}
                onAddAndClose={this.props.onAddAndClose ? () => {
                  this.props.onAddAndClose();
                  this.hidePanel();
                } : undefined}
                onSaveAsDraft={this.props.onSaveAsDraft ? () => {
                  this.props.onSaveAsDraft();
                  this.hidePanel();
                } : undefined}
                onReviewReject={this.props.onReviewReject ? () => {
                  this.props.onReviewReject();
                  this.hidePanel();
                } : undefined}
                onReviewApprove={this.props.onReviewApprove ? () => {
                  this.props.onReviewApprove();
                  this.hidePanel();
                } : undefined}
                onReviewSave={this.props.onReviewSave ? () => {
                  this.props.onReviewSave();
                  this.hidePanel();
                } : undefined}
                onCopyLink={this.props.onCopyLink ? () => this.props.onCopyLink() : undefined}
                onExportToCalender={this.props.onExportToCalender}
                onEmailLink={this.props.onEmailLink ? () => this.props.onEmailLink() : undefined}
                enableTranslation={this.props.enableTranslation}
                dataAutomationIdPrefix={this.props.dataAutomationIdPrefix}
                previousMessage={this.props.previousMessage ?
                  () => {
                    this.props.previousMessage();
                    document.querySelector("." + styles.slimPanelContentArea).scrollTop = 0;
                  } : undefined
                }
                nextMessage={this.props.nextMessage ?
                  () => {
                    this.props.nextMessage();
                    document.querySelector("." + styles.slimPanelContentArea).scrollTop = 0;
                  } : undefined
                }
                onClearFilters={this.props.onClearFilters ?
                  () => {
                    this.props.onClearFilters();
                  } : undefined
                }
                close={() => {
                  this.props.close();
                  this.hidePanel();
                }}
                likesUpdated={(likes: any[]) => {
                  this.props.likesUpdated(likes);
                  this.setState({ likes }, () => this.forceUpdate());
                }}
                commentsUpdated={(comments: number) => {
                  this.props.commentsUpdated(comments);
                  this.setState({ commentCount: comments });
                }}
                commentsClicked={() => {
                  if (this.props.commentClicked) {
                    this.props.commentClicked();
                    var my_element = document.getElementById(`IALikesAndComments_${this.props.instance.instanceName}_${this.props.itemId}_${this.props.webpartId}`);
                    my_element.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                      inline: "nearest"
                    });
                  }
                }
                }
                languages={Helper.getLanguages(this.localizationService)}
                isTranslating={this.props.isTranslating}
                onTranslate={(language: string) => {
                  this.props.onTranslate(language, this.props.itemId);
                }
                }
                enablePrint={this.props.enablePrint ?? (this.props.instance && this.props.instance.settings && this.props.instance.settings.enablePrinting)}
                onPrint={() => {
                  Helper.imageHasCORS(this.props.headerImage).then((hasCORS) => {
                    this.setState({ headerImageHasCORS: hasCORS }, () => this.props.printMessage());
                  });
                }}
                isContentCreator={this.props.isContentCreator}
                openedFromAnotherMessage={this.props.openedFromAnotherMessage}
              />
            }
            {this.props.footer != undefined &&
              <div
                className={styles.IA_footer}
                style={{
                  borderColor: this.props.darkMode ? "#727272" : "#eaeaea",
                }}
              >
                {this.props.footer}
              </div>
            }
            {this.props.loading &&
              <>
                {this.props.skeletonLoaderElements?.length > 0
                  ?
                  <div
                    className={styles.IA_skeletonContainer}
                    style={{
                      paddingTop: this.props.showNavigationBar ? 44 : 0
                    }}
                  >
                    {this.props.skeletonLoaderElements.map(skeleton => {
                      return <IASkeleton skeleton={skeleton} />
                    })}
                  </div>
                  :
                  <div
                    className={styles.IA_loading}
                    style={{
                      backgroundColor: this.props.darkMode ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.5)"
                    }}
                  >
                    <IASpinner key={Helper.getRandomStringKey()} color={this.props.spinnerColor ?? this.props.highlightColor} />
                    <div className={styles.IA_weAreWorkingOnIt}>{this.localizationService.strings.Composer_WeAreWorkingOnIt}</div>
                  </div>
                }
              </>
            }
          </div>
        </Swipeable>
        {this.props.isMobile && this.props.mobileCloseButton &&
          <div
            className={styles.IA_mobileCloseButton}
            onClick={() => this.props.close()}
            style={{
              visibility: this.props.open ? "visible" : "hidden",
              opacity: this.props.open ? 0.7 : 0,
              backgroundColor: this.props.highlightColor,
            }}
          >
            <IAIcon
              title="Close"
              size={22}
              color={Helper.isLightColor(this.props.highlightColor) ? "#000000" : "#ffffff"}
              style={{
                marginTop: 9,
                marginLeft: 9
              }}
            />
          </div>
        }
        <IAImageViewer
          token={this.props.token}
          tenant={this.props.tenant}
          environment={this.props.environment}
          images={this.props.imageViewerImages}
          index={this.props.imageViewerIndex}
          isMobile={this.props.isMobile}
          close={this.props.closeImageViewer ? () => this.props.closeImageViewer() : undefined}
        />
        {this.props.printing &&
          <div
            style={{ backgroundColor: this.props.highlightColor, width: printPanelWidth }}
            className={styles.IA_printOverlay}
          >
            <IAIcon
              title={"Print"}
              size={100}
              color="white"
            />
            <p className={styles.IA_printOverlayText}>
              {new LocalizationService().strings.Messages_ViewPanel_PrintOverlayText}
            </p>
            <div style={{
              width: 200,
              height: 15,
              backgroundColor: "rgba(0,0,0,0.2)",
              borderRadius: 15,
              overflow: "hidden"
            }}>
              <div
                style={{
                  height: "100%",
                  width: this.props.printProgressPercentage === undefined || this.props.printProgressPercentage === 0 ? 0 : `${this.props.printProgressPercentage}%`,
                  backgroundColor: "white",
                  borderRadius: 15,
                  transition: "width 0.5s",
                }}
              />
            </div>
          </div>
        }
      </div>
    );
  }


}