exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_canteenMenu_1aKAzcwdjzPXQhd-5hdaYx {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  box-sizing: border-box;\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n}\n\n.IA_canteenMenuOverlay_1od7Vpmt3mIqIpiKveYD2y {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n}\n\n.IA_headline_1cgKUGY-JHWDAoLBNnSqNg {\n  width: 100%;\n  text-align: center;\n  position: relative;\n}\n\n.IA_column_3_bZCFxgeDx01afPI0F_ST {\n  float: left;\n  box-sizing: border-box;\n  height: 100%;\n  position: relative;\n}", ""]);

// Exports
exports.locals = {
	"IA_canteenMenu": "IA_canteenMenu_1aKAzcwdjzPXQhd-5hdaYx",
	"IA_canteenMenuOverlay": "IA_canteenMenuOverlay_1od7Vpmt3mIqIpiKveYD2y",
	"IA_headline": "IA_headline_1cgKUGY-JHWDAoLBNnSqNg",
	"IA_column": "IA_column_3_bZCFxgeDx01afPI0F_ST"
};